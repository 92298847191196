<template>
  <main>
    <section>
      <div class="container">
        <div class="card-body">
          <div class="mb-2">
            <label class="form-label">
              Plan Name<span class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Plan Name"
              v-model="planData.plan_name"
            />
          </div>
          <div>
            <span v-if="errorData.plan_name != ''" style="color: red">{{
              errorData.plan_name
            }}</span>
          </div>
          <div class="mb-2">
            <label class="form-label"
              >Duration In Months <span class="text-red">*</span>
            </label>
            <input
              type="number"
              class="form-control"
              placeholder="Plan Duration"
              v-model="planData.duration"
            />
          </div>
          <div>
            <span v-if="errorData.duration != ''" style="color: red">{{
              errorData.duration
            }}</span>
          </div>
          <div class="mb-2">
            <label class="form-label"
              >Plan Start's From <span class="text-red">*</span>
            </label>
            <select class="form-control" v-model="planData.start_month">
              <option value="">--Select Month--</option>
              <option value="1">Janaury</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option valueh="11">November</option>
              <option value="12">December</option>
              <!-- <option planData.duration="Super_Admin">January</option> -->
            </select>
          </div>
          <div>
            <span v-if="errorData.month != ''" style="color: red">{{
              errorData.month
            }}</span>
          </div>
          <div class="mb-2">
            <label class="form-label"
              >Plan Amount <span class="text-red">*</span>
            </label>
            <input
              type="number"
              class="form-control"
              placeholder="Plan Amount"
              v-model="planData.plan_amount"
            />
          </div>
          <div>
            <span v-if="errorData.plan_amount != ''" style="color: red">{{
              errorData.plan_amount
            }}</span>
          </div>
          <div class="mb-2">
            <label class="form-label"
              >Description <span class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Description"
              v-model="planData.description"
            />
          </div>
          <div>
            <span v-if="errorData.description != ''" style="color: red">{{
              errorData.description
            }}</span>
          </div>
          <div class="mb-2">
            <label class="form-label"
              >Due Date <span class="text-red">*</span>
            </label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter Due Date between 1 to 28"
              v-model="planData.due_date"
            />
          </div>
          <div>
            <span v-if="errorData.due_date != ''" style="color: red">{{
              errorData.due_date
            }}</span>
          </div>
          <button class="btn btn-primary" @click="addPlans()">Submit</button>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="table-responsive border-0">
          <table class="table align-middle p-4 mb-0 table-hover table-shrink">
            <thead class="table-light">
              <tr>
                <th scope="col" class="border-0 rounded-start">#</th>
                <th scope="col" class="border-0">Description</th>
                <th scope="col" class="border-0">Due Date</th>
                <th scope="col" class="border-0">Duration</th>
                <th scope="col" class="border-0">Plan amount</th>
                <th scope="col" class="border-0">Plan name</th>
              </tr>
            </thead>
            <tbody class="border-top-0">
              <tr v-for="(booking, key) in PlanList" :key="key">
                <td>
                  <h6 class="mb-0">{{ key + 1 }}</h6>
                </td>
                <td>
                  {{ booking.description }}
                </td>
                <td>
                  {{ booking.due_date }}
                </td>
                <td>
                  {{ booking.duration }}
                </td>
                <td>
                  {{ booking.plan_amount }}
                </td>
                <td>
                  {{ booking.plan_name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import axios from "axios";
export default {
  name: "Login",
  data() {
    return {
      cardMeth: "",
      itemMeth: "",
      postPayment: {
        payment_status: false,
      },
      paymentCardNumber: "",
      paymentsDetails: "",
      planData: {
        plan_name: "",
        duration: "",
        plan_amount: "",
        description: "",
        due_date: "",
        start_month: 0,
      },
      errorData: {
        plan_name: "",
        duration: "",
        plan_amount: "",
        description: "",
        due_date: "",
        month: "",
      },
      plan_Add: false,
      mobileNumError: "",
      mobileMemberError: "",
      cardLim: false,
      cardLimitExd: "",
      cardLimMem: false,
      cardLimitExdMem: "",
      cards: [],
      selectedCards: [],
      selectedCard: "",
      CardErrorMessage: "",
      cardErrorMsg: false,
      CardAdded: false,
      num_Added: "",
      new_card: {
        card_no: "",
      },
      error_same_password: "",
      add_User: false,
      dashUser: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        user_type: "",
      },
      add_card: true,
      fields: [{ value: "" }], // Initial input field
      add_agent: false,
      memOn: false,
      add_card: false,
      total_Cards: "",
      newAgent: {
        name: "",
        agent_mobile_number: "",
        card_ids: "",
      },
      newMember: {
        name: "",
        mobile_number: "",
        card_ids: "",
      },
      total_memCards: "",
      selMemberCard: "",
      selMemberCards: [],
      totalMemberList: "",
      PlanList: "",
    };
  },
  mounted() {
    this.getPlans();
  },
  methods: {
    getPlans() {
      let vm = this;
      vm.$store
        .dispatch("get", { uri: "administrator/plans/" })
        .then((response) => {
          this.PlanList = response?.data;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    addPlans() {
      this.errorData.plan_name = "";
      this.errorData.duration = "";
      this.errorData.plan_amount = "";
      this.errorData.description = "";
      this.errorData.month = "";
      this.errorData.due_date = "";
      if (this.planData.plan_name == "") {
        this.errorData.plan_name = "Please Enter Plan Name";
      } else if (this.planData.duration == "") {
        this.errorData.duration = "Please Enter Plan Duration";
      } else if (this.planData.plan_amount == "") {
        this.errorData.plan_amount = "Please Enter Plan Amount";
      } else if (this.planData.description == "") {
        this.errorData.description = "Please Enter Plan Description";
      } else if (this.planData.start_month === 0) {
        this.errorData.month = "Please Enter Plan Month";
      } else if (this.planData.due_date > 28 || this.planData.due_date < 1) {
        this.errorData.due_date = "Please select Due Date between 1-28";
      } else {
        console.log("this.newMember", this.planData);
        let loader = this.$loading.show();
        this.$store
          .dispatch("post", {
            uri: "administrator/plan/create/",
            data: this.planData,
          })
          .then((response) => {
            loader.hide();
            this.getPlans();
            console.log(response);
          })
          .catch((errors) => {
            loader.hide();
            console.log(errors);
          });
      }
    },
  },
};
</script>
