<template>
    <main>
        <div class="container" style="text-align: left;" >
            <br>
          <br>
          <img class="mb-4" src="https://www.devalayas.in/assets/images/tc.png" alt="" />
         
          <br>
          <p><h5>Terms and Conditions :</h5></p>
          <p class="text-justify">Eligibility : Only relatives and friends are allowed to participate in the scheme.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">Total Members: There are 999 members in the scheme.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">Duration: The scheme lasts for 10 months.
&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">Draws: Three draws will be conducted every month. Winner are displayed in Samruddhi Memebers Mobile App.
&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">Total Contribution: Over the 10-month period, each member contributes Rs. 1200/- per month, totaling Rs. 12,000/-.
&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">Prizes: Members who win a motorcycle (basic model) will have their R.T.O. (Road Transport Office) registration and insurance expenses covered. However, any other expenses related to the motorcycle, such as additional accessories or upgrades, will be borne by the winners themselves.
&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">Payment Deadline: All members are required to pay their installment amount at least 10 days before the date of the draw. Failure to do so will result in their exclusion from participating in the draw.
&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">Warranty: Items won through the scheme, particularly those acquired through draws (Any Electronics items or Vehicles) are subject to the warranty provided by the Manufacturers. The warranty coverage will be in accordance with the terms and conditions specified by the Company manufacturing the item.
&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">Receipts: After paying their installment, all members should receive a receipt immediately through Samruddhi Members Mobile App. These receipts serve as proof of payment for all installments. Members must retain these receipts as they will be required to claim lucky prizes and consolation prizes.
&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">Membership Cancellation: If any member fails to pay more than one installment, their membership will be canceled without prior notice. The money paid by the member is non-refundable and non-transferable. The decision of the company regarding membership cancellation is final.
&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">Item Collection: After the third draw, winners can obtain their selected items by making full payment. Remaining members will receive consolation items of their choice displayed on the Card within 30 days of the last draw.
&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">Jurisdiction: The scheme is subject to the jurisdiction of the Belagavi Court.
&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">Variation in Delivered Items: The items delivered to members may differ from the image, color, size and brand shown in promotional materials.
&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">No Further Installments for Winners: Winning members are not required to pay further installments after winning a prize.
&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">Draw Schedule: Draws will be held on the 11th of every month at 5:00 PM in the presence of members who are present at the specified location and also with live telecast in the Youtube. 
&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">Postponement of Draws: The Company reserves the right to postpone the draw date! Up to one month for any reason. Members will be informed about such changes.
&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">Handling Charge for Consolation Items: At the end of the scheme, members receiving consolation items will be required to pay a handling charge of Rs. 200/- to the organization.`;
&nbsp;</p>
          <p class="text-justify">&nbsp;</p>          
      </div>
    </main>
</template>
<script>

export default {  
    name: "Terms",
    mounted(){
      window.scrollTo(0,0);
      }   
      };

</script>