<template>
  <main>
    <section>
      <div class="container">
        <div class="card-body">
          <div
            v-if="userAdded"
            class="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <strong>Successfully Created User {{ num_Added }}.</strong>
          </div>
          <div class="mb-2">
            <label class="form-label">
              Name <span class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Name"
              v-model="dashUser.name"
            />
          </div>
          <span v-if="errors.name != ''" style="color: red">{{
            errors.name
          }}</span>
          <div class="mb-2">
            <label class="form-label">
              Email Id <span class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Email Id"
              v-model="dashUser.email"
            />
          </div>
          <span v-if="errors.email != ''" style="color: red">{{
            errors.email
          }}</span>
          <div class="mb-2">
            <label class="form-label">
              Mobile Number <span class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Mobile Number"
              v-model="mobile_number1"
            />
          </div>
          <span v-if="errors.mobile_number != ''" style="color: red">{{
            errors.mobile_number
          }}</span>
          <div class="mb-2">
            <label class="form-label"
              >Password <span class="text-red">*</span>
            </label>
            <input
              type="password"
              class="form-control"
              placeholder="Enter Password"
              v-model="dashUser.password"
            />
          </div>
          <span v-if="errors.password != ''" style="color: red">{{
            errors.password
          }}</span>
          <div class="mb-2">
            <label class="form-label"
              >Confirm Password <span class="text-red">*</span>
            </label>
            <input
              type="password"
              class="form-control"
              placeholder="Confirm Password"
              v-model="dashUser.confirm_password"
            />
          </div>
          <span v-if="errors.confirm_password != ''" style="color: red">{{
            errors.confirm_password
          }}</span>
          <div class="mb-2">
            <label class="form-label"
              >User Type <span class="text-red">*</span>
            </label>
            <select class="form-control" v-model="dashUser.user_type">
              <option value="Admin">Distributer</option>
            </select>
          </div>
          <span v-if="errors.user_type != ''" style="color: red">{{
            errors.user_type
          }}</span>
          <!-- <span v-if="show" style="color: red">{{ error_same_password }}</span> -->
          <!--  -->
          <div class="col-lg-2 d-grid">
            <a
              class="btn btn-lg-4 btn-primary mb-0"
              href="#"
              @click.prevent="submitDshUser"
              >Submit User
            </a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import axios from "axios";
export default {
  name: "Login",
  data() {
    return {
      show: false,
      userAdded: false,
      error_same_password: "",
      dashUser: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        user_type: "",
        mobile_number: "",
      },
      mobile_number1: "",
      errors: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        user_type: "",
        mobile_number: "",
      },
    };
  },

  methods: {
    submitDshUser() {
      let vm = this;
      this.errors.name = "";
      this.errors.email = "";
      this.errors.password = "";
      this.errors.confirm_password = "";
      this.errors.user_type = "";
      this.errors.mobile_number = "";
      if (this.dashUser.name == "") {
        this.errors.name = "Please Enter Name";
      } else if (this.dashUser.email == "") {
        this.errors.email = "Please Enter Email";
      } else if (
        this.mobile_number1 == "" ||
        this.mobile_number1.length != 10
      ) {
        this.errors.mobile_number = "Please Enter Valid Mobile Number";
      } else if (this.dashUser.password == "") {
        this.errors.password = "Please Enter Password";
      } else if (this.dashUser.confirm_password == "") {
        this.errors.confirm_password = "Please confirm Password";
      } else if (this.dashUser.confirm_password != this.dashUser.password) {
        this.errors.confirm_password =
          "Oops! It looks like the passwords you entered don't match";
      } else if (this.dashUser.user_type == "") {
        this.errors.user_type = "Select User Type";
      } else {
        this.dashUser.mobile_number = "+91" + this.mobile_number1;
        let loader = this.$loading.show();
        this.$store
          .dispatch("post", {
            uri: "administrator/create-user/",
            data: vm.dashUser,
          })
          .then((response) => {
            loader.hide();
            this.dashUser.name = "";
            this.dashUser.email = "";
            this.dashUser.password = "";
            this.dashUser.confirm_password = "";
            this.dashUser.user_type = "";
            this.mobile_number1 = "";
            this.userAdded = true;
          })
          .catch((errors) => {
            loader.hide();
          });
      }
    },
  },
};
</script>
