<template>
  <main>
    <section>
      <div class="container">
        <div class="card-body">
          <div class="mb-2">
            <label class="form-label">
              Member ID <span class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Member ID"
              v-model="winn.member"
            />
          </div>
          <div class="mb-2">
            <label class="form-label">
              Month <span class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="January"
              v-model="winn.month"
            />
          </div>
          <div class="mb-2">
            <label class="form-label">
              Year <span class="text-red">*</span>
            </label>
            <input
              type="number"
              class="form-control"
              placeholder="Year"
              v-model="winn.year"
            />
          </div>
          <div class="mb-2">
            <label class="form-label"
              >Rank <span class="text-red">*</span>
            </label>
            <input
              type="number"
              class="form-control"
              placeholder="Rank"
              v-model="winn.rank"
            />
          </div>
          <div class="mb-2">
            <label class="form-label"
              >Member Image <span class="text-red">*</span>
            </label>
            <input
              type="file"
              ref="imageInput"
              @change="handleImageChange"
              accept="image/*"
              class="form-control"
            />
          </div>
          <div class="mb-2">
            <label class="form-label"
              >Description <span class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Description"
              v-model="winn.description"
            />
          </div>
          <span v-if="Errormessage != ''" style="color: red">{{
            Errormessage
          }}</span>
        </div>
        <button class="btn btn-primary" @click="addagentDetails">Submit</button>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="table-responsive border-0">
          <table class="table align-middle p-4 mb-0 table-hover table-shrink">
            <thead class="table-light">
              <tr>
                <th scope="col" class="border-0 rounded-start">#</th>
                <th scope="col" class="border-0">Rank</th>
                <th scope="col" class="border-0">Mobile Number</th>
                <th scope="col" class="border-0">Name</th>
                <th scope="col" class="border-0">Description</th>
                <th scope="col" class="border-0">Month</th>
                <th scope="col" class="border-0">Year</th>
              </tr>
            </thead>
            <tbody class="border-top-0">
              <tr v-for="(booking, key) in winners" :key="key">
                <td>
                  <h6 class="mb-0">{{ key + 1 }}</h6>
                </td>
                <td>
                  {{ booking.rank }}
                </td>
                <td>
                  {{ booking.member?.mobile_number }}
                </td>
                <td>
                  {{ booking.member?.name }}
                </td>
                <td>
                  {{ booking.description }}
                </td>
                <td>
                  {{ booking.month }}
                </td>
                <td>
                  {{ booking.year }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import axios from "axios";
export default {
  name: "Login",
  data() {
    return {
      winn: {
        member: "",
        month: "",
        year: "",
        rank: "",
        image: "",
        description: "",
      },
      image: "",
      selectedImageFile: "",
      Errormessage: "",
      winners: "",
    };
  },
  mounted() {
    this.getWinners();
  },
  methods: {
    getWinners() {
      let vm = this;
      vm.$store
        .dispatch("get", { uri: "administrator/winners/" })
        .then((response) => {
          this.winners = response?.data;
          console.log("winners", this.winners);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    handleImageChange(event) {
      this.selectedImageFile = event.target.files[0];
    },
    addagentDetails() {
      this.Errormessage = "";
      if (
        this.selectedImageFile == "" ||
        this.winn.member == "" ||
        this.winn.month == "" ||
        this.winn.year == "" ||
        this.winn.rank == "" ||
        this.winn.description == ""
      ) {
        this.Errormessage = "Please Fill All Details";
      } else {
        const formData = new FormData();
        formData.append("member", this.winn.member);
        formData.append("month", this.winn.month);
        formData.append("year", this.winn.year);
        formData.append("rank", this.winn.rank);
        formData.append("image", this.selectedImageFile);
        formData.append("description", this.winn.description);
        let loader = this.$loading.show();
        this.$store
          .dispatch("postWinner", {
            uri: "administrator/winner/add",
            data: formData,
          })
          .then((response) => {
            loader.hide();
            this.selectedImageFile = "";
            this.winn.member = "";
            this.winn.month = "";
            this.winn.year = "";
            this.winn.rank = "";
            this.winn.description == "";
            this.getWinners();
          })
          .catch((errors) => {
            loader.hide();
            console.log(errors);
          });
      }
    },
  },
};
</script>
