<template>
  <main>
    <section>
      <div class="container d-flex px-0 px-sm-4">
        <div class="row justify-content-center align-items-center m-auto">
          <div class="col-12">
            <div class="bg-mode shadow rounded-3 overflow-hidden">
              <div class="row g-0">
                <div
                  class="col-lg-6 d-flex align-items-center order-2 order-lg-1"
                >
                  <div class="p-3 p-lg-5">
                    <img
                      style="width: 80%"
                      class="mb-4"
                      src="@/assets/banner.svg"
                      alt=""
                    />
                  </div>
                  <div class="vr opacity-2 d-none d-lg-block"></div>
                </div>
                <div class="col-lg-6 order-1">
                  <div class="p-1 p-sm-6 text-center">
                    <h5 style="padding: 20px">
                      Welcome to Samruddhi Dashboard.
                    </h5>
                    <div>
                      <button
                        class="btn btn-primary"
                        @click="dist"
                        style="width: 50%; border-radius: 50%"
                      >
                        I'm Distributer
                      </button>

                      <button
                        class="btn btn-primary"
                        style="width: 50%; border-radius: 50%"
                        @click="agentClick"
                      >
                        I'm Associate
                      </button>
                    </div>
                    <div :key="elementKey" id="recaptcha-container"></div>
                    <div v-if="agentLive">
                      <div class="mb-3" style="text-align: left">
                        <label class="form-label"
                          >Mobile Number <span class="text-red">*</span></label
                        >
                        <input
                          type="text"
                          v-model="NumberCode"
                          class="form-control"
                          placeholder="Mobile Number"
                          maxlength="10"
                          :class="{ 'is-invalid': error_loginNumber != '' }"
                        />
                        <span
                          v-if="error_loginNumber != ''"
                          class="invalid-feedback"
                          >{{ error_loginNumber }}</span
                        >
                      </div>
                      <div class="mb-0">
                        <button @click="send_otp" class="btn btn-primary w-100">
                          Send OTP
                        </button>
                      </div>
                    </div>
                    <div v-if="sendOtp">
                      <div class="mb-3" style="text-align: left">
                        <label class="form-label"
                          >Verify <span class="text-red">*</span></label
                        >
                        <input
                          type="text"
                          v-model="verificationCode"
                          class="form-control"
                          placeholder="Enter OTP"
                          maxlength="6"
                          :class="{ 'is-invalid': otp_error != '' }"
                          style="text-align: center"
                        />
                        <span v-if="otp_error != ''" class="invalid-feedback">{{
                          otp_error
                        }}</span>
                      </div>
                      <div class="mb-0">
                        <button
                          v-if="!resendButton"
                          @click="verifyCode"
                          class="btn btn-primary w-100"
                        >
                          Verify OTP
                        </button>
                        <button
                          v-if="resendButton"
                          @click="agentClick"
                          class="btn btn-primary w-100"
                        >
                          Send Again
                        </button>
                      </div>
                    </div>
                    <div v-if="distLive">
                      <div class="mb-3" style="text-align: left">
                        <label class="form-label"
                          >Email ID <span class="text-red">*</span></label
                        >
                        <input
                          type="text"
                          v-model="csc.email"
                          class="form-control"
                          placeholder="User Email ID"
                          :class="{ 'is-invalid': error.email != '' }"
                        />
                        <span
                          v-if="error.email != ''"
                          class="invalid-feedback"
                          >{{ error.email }}</span
                        >
                      </div>
                      <!-- Password -->
                      <div class="mb-3" style="text-align: left">
                        <label class="form-label"
                          >Password <span class="text-red">*</span></label
                        >
                        <input
                          type="password"
                          v-model="csc.password"
                          class="form-control"
                          placeholder="Password"
                          :class="{ 'is-invalid': error.password != '' }"
                        />
                        <span
                          v-if="error.password != ''"
                          class="invalid-feedback"
                          >{{ error.password }}</span
                        >
                      </div>
                      <!-- Password -->
                      <!-- Submit button -->
                      <span v-if="loginError != ''" style="color: red">{{
                        loginError
                      }}</span>
                      <div class="mb-0">
                        <button
                          @click="autoLogin()"
                          class="btn btn-primary w-100"
                        >
                          Login
                        </button>
                      </div>
                    </div>
                    <!-- Submit button -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import firebase from "@/store/firebase.js";
import "firebase/database";
import "firebase/compat/auth";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export default {
  name: "Login",
  data() {
    return {
      user: {
        mobile_number: "",
        login_token: "",
        app_version: "",
        device_model: "",
        user_type: "",
      },
      csc: {
        email: "",
        password: "",
      },
      error: {
        email: "",
        password: "",
      },
      errors: [],
      distLive: false,
      agentLive: false,
      sendOtp: false,
      NumberCode: "",
      elementKey: 0,
      mobileNumber_code: "+91",
      phoneNumber: "",
      minutes: 3,
      seconds: 0,
      resendButton: false,
      isValidPhoneNumber: false,
      error_loginNumber: "",
      recaptcha: "",
      otp_error: "",
      verificationCode: "",
      verificationId: "",
      loginError: "",
      agentLogin: {
        mobile_number: "",
      },
    };
  },
  mounted() {
    this.$store.getters.token;
  },
  methods: {
    Login() {
      let vm = this;
      let loader = vm.$loading.show();
      vm.agentLogin.mobile_number = "+91" + this.NumberCode.trim();
      vm.$store
        .dispatch("post", {
          uri: "administrator/agent/login/",
          data: vm.agentLogin,
        })
        .then((response) => {
          loader.hide();
          console.log("responseAgent", response);
          vm.$store.dispatch("setUser", response.data?.data?.user_type);
          vm.$store.dispatch("setToken", response.data?.data?.token);
          localStorage.setItem("token", response.data?.data?.token);
          vm.$store.dispatch("setName", response.data?.data?.name);
          vm.$router.push("/");
        })
        .catch((error) => {
          console.log(error?.response?.data?.error);
          alert(error?.response?.data?.error);
          loader.hide();
        });
    },
    verifyCode() {
      let vm = this;
      let loader = vm.$loading.show();
      this.otp_error = "";
      const credential = firebase.auth.PhoneAuthProvider.credential(
        this.verificationId,
        this.verificationCode
      );
      firebase
        .auth()
        .signInWithCredential(credential)
        .then((result) => {
          this.user.mobile_number =
            this.mobileNumber_code.trim() + this.NumberCode.trim();
          this.elementKey += 1;
          loader.hide();
          vm.Login();
        })
        .catch((error) => {
          console.log(error);
          loader.hide();
          this.otp_error = "Invalid OTP";
        });
    },
    startCountdown() {
      let vm = this;
      this.timer = setInterval(() => {
        if (this.minutes === 0 && this.seconds === 0) {
          clearInterval(this.timer);
          vm.resendButton = true;
          vm.NumberCode = "";
        } else {
          if (this.seconds === 0) {
            this.minutes--;
            this.seconds = 59;
          } else {
            this.seconds--;
          }
        }
      }, 1000);
    },
    send_otp() {
      let vm = this;
      let loader = vm.$loading.show();
      vm.resendButton = false;
      const appVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" }
      );
      const regex = /^\+[0-9]{11,12}$/;
      vm.error_loginNumber = "";
      vm.phoneNumber = vm.mobileNumber_code.trim() + this.NumberCode.trim();
      vm.isValidPhoneNumber = regex.test(vm.phoneNumber);
      if (vm.mobileNumber_code == "") {
        vm.error_loginNumber = "Please select country code";
      } else if (this.NumberCode == "") {
        vm.error_loginNumber = "Please enter mobile number";
      } else if (!vm.isValidPhoneNumber) {
        vm.error_loginNumber =
          "Enter your number in the format '+919876543210'.";
      } else {
        const phoneNumber = parsePhoneNumberFromString(this.phoneNumber);
        firebase
          .auth()
          .signInWithPhoneNumber(this.phoneNumber, appVerifier)
          .then((confirmationResult) => {
            this.verificationId = confirmationResult.verificationId;
            this.numEntered();
            this.startCountdown();
            vm.phoneNumber = phoneNumber; // Assuming this.phoneNumber is a property of your component...
          })
          .then((result) => {
            loader.hide();
            console.log("Phone authentication successful");
          })
          .catch((error) => {
            this.error_loginNumber = "Please try again later";
            loader.hide();
            console.log(error);
            this.elementKey += 1;
          });
      }
    },
    numEntered() {
      this.agentLive = false;
      this.distLive = false;
      this.sendOtp = true;
    },
    agentClick() {
      this.agentLive = true;
      this.distLive = false;
      this.sendOtp = false;
      this.elementKey += 1;
    },
    dist() {
      this.agentLive = false;
      this.distLive = true;
      this.sendOtp = false;
    },
    autoLogin() {
      let vm = this;
      this.error.email = "";
      this.error.password = "";
      if (vm.csc.email == "") {
        this.error.email = "Please Enter Email ID";
      } else if (this.csc.password == "") {
        this.error.password = "Please Enter Password";
      } else {
        console.log(vm.csc);
        let loader = vm.$loading.show();
        vm.$store
          .dispatch("post", { uri: "administrator/login/", data: vm.csc })
          .then((response) => {
            loader.hide();
            console.log("response", response);
            vm.$store.dispatch("setUser", response.data.user_type);
            vm.$store.dispatch("setName", response.data.name);
            vm.$store.dispatch("setToken", response.data.token);
            vm.$router.push("/");
          })
          .catch((error) => {
            this.loginError = "Invalid email or password.";
            loader.hide();
          });
      }
    },
  },
};
</script>
