<template>
  <main style="background-color: rgb(255, 255, 255)">
    <div class="carousel-inner">
      <div class="carousel-item active ba-1">
        <img
          :src="require(`@/assets/banners/days/logoHome.jpg`)"
          class="d-block w-100"
          alt="..."
        />
      </div>
    </div>
    <div v-if="this.$store.getters.user == 'Associate'">
      <div
        class="container"
        v-if="
          panCheck == null || nameCheck == '' || agent_imagesCheck.length == 0
        "
      >
        <br />
        <br />
        <h3 style="text-align: center">Update Your Profile To Check Cards</h3>
        <div class="mb-2">
          <label class="form-label"
            >Taluk <span class="text-red" style="color: red">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Taluk"
            v-model="Taluk"
          />
        </div>
        <span v-if="errorTaluk != ''" style="color: red">{{ errorTaluk }}</span>
        <div class="mb-2">
          <label class="form-label"
            >District<span class="text-red" style="color: red">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="District"
            v-model="District"
          />
        </div>
        <span v-if="errorDistrict != ''" style="color: red">{{
          errorDistrict
        }}</span>
        <div class="mb-2">
          <label class="form-label"
            >UPI Qr Code: <span class="text-red" style="color: red">*</span>
          </label>
          <input
            type="file"
            ref="qrcode"
            @change="getQrCode"
            accept="image/*"
            class="form-control"
          />
        </div>
        <span v-if="errorqr_code != ''" style="color: red">{{
          errorqr_code
        }}</span>
        <div class="mb-2">
          <label class="form-label"
            >Aadhar Card: <span class="text-red" style="color: red">*</span>
          </label>
          <input
            type="file"
            ref="aadharCard"
            @change="getAadhar"
            accept="image/*"
            class="form-control"
          />
        </div>
        <span v-if="erroraadharImage != ''" style="color: red">{{
          erroraadharImage
        }}</span>

        <div class="mb-2">
          <label class="form-label">
            Selfie Image : <span class="text-red" style="color: red">*</span>
          </label>
          <input
            type="file"
            ref="imageInput1"
            @change="(event) => handleImageChange(event)"
            accept="image/*"
            class="form-control"
          />
        </div>
        <span v-if="errorImage1 != ''" style="color: red">{{
          errorImage1
        }}</span>
        <hr />
        <div class="mb-2">
          <label class="form-label">Address </label>
          <input
            type="text"
            class="form-control"
            placeholder="Address"
            v-model="Address"
          />
        </div>

        <div class="mb-2">
          <label class="form-label">Pan Card: </label>
          <input
            type="file"
            ref="panCard"
            @change="getPan"
            accept="image/*"
            class="form-control"
          />
        </div>
        <br />
        <div>
          <button @click="SubmitAgentDetails" class="btn btn-primary">
            Submit
          </button>
        </div>
      </div>
    </div>
    <div v-if="this.$store.getters.user == 'Super_Admin'" class="container">
      <hr />
      <button
        class="btn btn-primary"
        @click="distShow"
        style="margin-right: 27%"
      >
        Distributer List
      </button>
      <button
        @click="associateShow"
        class="btn btn-primary"
        style="margin-right: 27%"
      >
        Associate List
      </button>
      <button @click="memberShow" class="btn btn-primary">Member List</button>
    </div>
    <div v-if="this.$store.getters.user == 'Super_Admin' && DistrubtorShow">
      <h4 style="text-align: center; padding: 30px">
        Registered Distributer's
      </h4>
      <section>
        <div class="table-responsive border-0">
          <table class="table align-middle p-4 mb-0 table-hover table-shrink">
            <thead class="table-light">
              <tr>
                <th scope="col" class="border-0 rounded-start">#</th>
                <th scope="col" class="border-0">Name</th>
                <th scope="col" class="border-0">Email ID</th>
                <th scope="col" class="border-0">Mobile Number</th>
                <th scope="col" class="border-0">No of Associate's</th>
                <th scope="col" class="border-0">Total Alloted Cards</th>
                <th scope="col" class="border-0">
                  Assigned cards to Associate
                </th>
                <th scope="col" class="border-0">Pending Cards</th>
                <th scope="col" class="border-0">Action</th>
              </tr>
            </thead>
            <tbody class="border-top-0">
              <tr v-for="(booking, key) in TotalRegMember" :key="key">
                <td>
                  <h6 class="mb-0">{{ key + 1 }}</h6>
                </td>
                <td>
                  {{ booking.name }}
                </td>
                <td>
                  {{ booking.email }}
                </td>
                <td>
                  {{ booking.mobile_number }}
                </td>
                <td>
                  {{ booking.agent_count }}
                </td>
                <td>
                  {{ booking.card_count }}
                </td>
                <td>{{ booking.total_assigned_cards }}</td>
                <td>{{ booking.card_count - booking.total_assigned_cards }}</td>
                <td>
                  <button
                    @click="assignCards(booking.id)"
                    class="btn btn-primary"
                  >
                    Add Cards
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <!-- Member List -->
    <div
      v-if="this.$store.getters.user == 'Super_Admin' && MemberShow"
      class="container"
    >
      <h4 style="text-align: center; padding: 30px">Registered Members's</h4>
      <br />
      <div class="row g-3 justify-content-center align-items-center">
        <div class="col-lg-10">
          <div class="form-input-dropdown position-relative">
            <input
              v-model="memberSearch"
              placeholder="Search using Mobile Number"
              class="form-control form-control-lg me-1 ps-5"
              type="text"
            />
          </div>
        </div>
        <div class="col-lg-2 d-grid">
          <a
            class="btn btn-lg btn-primary mb-0"
            href="#"
            @click.prevent="getAllMemberList"
            >Search</a
          >
        </div>
      </div>

      <section>
        <button class="btn btn-lg btn-primary mb-0" @click.prevent="ExportAll">
          Export All
        </button>
        <hr />
        <div class="table-responsive border-0">
          <table class="table align-middle p-4 mb-0 table-hover table-shrink">
            <thead class="table-light">
              <tr>
                <th scope="col" class="border-0 rounded-start">#</th>
                <th scope="col" class="border-0">Name</th>
                <th scope="col" class="border-0">Mobile Number</th>
                <th scope="col" class="border-0">Member ID</th>
                <th scope="col" class="border-0">Card 1</th>
                <th scope="col" class="border-0">Card 2</th>
                <th scope="col" class="border-0">Associate (Mobile Number)</th>
                <th scope="col" class="border-0">Single</th>
                <th scope="col" class="border-0">Double</th>
              </tr>
            </thead>
            <tbody class="border-top-0">
              <tr v-for="(item, key) in TotalMemberList" :key="key">
                <td>
                  <h6 class="mb-0">{{ key + 1 }}</h6>
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.mobile_number }}
                </td>
                <td>
                  {{ item.id }}
                </td>
                <td>
                  {{ item.member_card[0]?.card_no }}
                </td>
                <td>
                  {{ item.member_card[1]?.card_no }}
                </td>
                <td v-if="item.member_card[0]?.agent?.name">
                  {{ item.member_card[0]?.agent?.name }}&nbsp;({{
                    item.member_card[0]?.agent?.mobile_number
                  }})
                </td>
                <td v-if="!item.member_card[0]?.agent?.name"></td>
                <td>
                  <button
                    class="btn btn-primary"
                    @click="viewPayments(item.id, item.member_card[0]?.id)"
                  >
                    View
                  </button>
                </td>
                <td>
                  <button
                    v-if="item.member_card[1]?.card_no"
                    class="btn btn-primary"
                    @click="viewPayments(item.id, item.member_card[1]?.id)"
                  >
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <!-- Member List -->
    <!-- <div class="modal" v-if="selectedItem" @click="closePopup">
      <div class="modal-content" @click.stop>
        <span class="close" @click="closePopup">&times;</span>
        <div>{{ selectedItem.card_no }}</div>
      </div>
    </div> -->
    <!-- Agent List -->
    <!--  -->
    <div class="container">
      <dialog id="dialogCard" class="daialbox">
        <div>
          <i
            class="fas fa-times-circle"
            style="
              font-size: 20px;
              color: rgb(42, 38, 38);
              float: right;
              cursor: pointer;
            "
            v-on:click="closeAgentsCard()"
          ></i>
        </div>
        <div class="table-responsive border-0">
          <div class="container">
            <h3>Associate Details :</h3>
            <table class="table align-middle p-4 mb-0 table-hover table-shrink">
              <thead class="table-light">
                <tr>
                  <th scope="col" class="border-0">Name</th>
                  <th scope="col" class="border-0">State</th>
                  <th scope="col" class="border-0">district</th>
                  <th scope="col" class="border-0">taluk</th>
                  <th scope="col" class="border-0">village</th>
                  <th scope="col" class="border-0">occupation</th>
                </tr>
              </thead>
              <tbody class="border-top-0">
                <tr>
                  <td v-if="CardsAgentname">
                    <span class="mb-0">{{ CardsAgentname }}</span>
                  </td>
                  <td v-if="!CardsAgentname">
                    <span class="mb-0"> - </span>
                  </td>
                  <td v-if="CardsAgentstate">
                    <span class="mb-0">{{ CardsAgentstate }}</span>
                  </td>
                  <td v-if="!CardsAgentstate">
                    <span class="mb-0"> - </span>
                  </td>
                  <td v-if="CardsAgentdistrict">
                    <span class="mb-0">{{ CardsAgentdistrict }}</span>
                  </td>
                  <td v-if="!CardsAgentdistrict">
                    <span class="mb-0"> - </span>
                  </td>
                  <td v-if="CardsAgenttaluk">
                    <span class="mb-0">{{ CardsAgenttaluk }}</span>
                  </td>
                  <td v-if="!CardsAgenttaluk">
                    <span class="mb-0"> - </span>
                  </td>
                  <td v-if="CardsAgentvillage">
                    <span class="mb-0">{{ CardsAgentvillage }}</span>
                  </td>
                  <td v-if="!CardsAgentvillage">
                    <span class="mb-0"> - </span>
                  </td>
                  <td v-if="CardsAgentoccupation">
                    <span class="mb-0">{{ CardsAgentoccupation }}</span>
                  </td>
                  <td v-if="!CardsAgentoccupation">
                    <span class="mb-0"> - </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <h3>Associate Cards :</h3>
            <table class="table align-middle p-4 mb-0 table-hover table-shrink">
              <thead class="table-light">
                <tr>
                  <th scope="col" class="border-0 rounded-start">#</th>
                  <th scope="col" class="border-0">Card No</th>
                  <th scope="col" class="border-0">Distributer Name</th>
                  <th scope="col" class="border-0">Assigned Member</th>
                  <th scope="col" class="border-0">Registered Date</th>
                  <th scope="col" class="border-0">Surrender Card</th>
                </tr>
              </thead>
              <tbody class="border-top-0">
                <tr v-for="(booking, key) in selectedItem" :key="key">
                  <td>
                    <h6 class="mb-0">{{ key + 1 }}</h6>
                  </td>
                  <td>
                    <span class="mb-0">{{ booking?.card_no }}</span>
                  </td>
                  <td>
                    <span class="mb-0">
                      {{ booking?.distributer?.name }} ({{
                        booking?.distributer?.mobile_number
                      }})
                    </span>
                  </td>
                  <td v-if="booking?.member?.name">
                    <span class="mb-0">
                      {{ booking?.member?.name }} ({{
                        booking?.member?.mobile_number
                      }})
                    </span>
                  </td>
                  <td v-if="!booking?.member?.name">
                    <span>Not Assigned</span>
                  </td>
                  <td v-if="booking?.member?.name">
                    {{ moment(booking?.member?.date_joined).format("MMMM Do") }}
                  </td>
                  <td v-if="!booking?.member?.name">-</td>
                  <td v-if="!booking?.member?.name">
                    <button
                      class="btn btn-primary"
                      @click="deleteCards(booking?.id)"
                    >
                      Delete
                    </button>
                  </td>
                  <td v-if="booking?.member?.name">-</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </dialog>
    </div>
    <div
      v-if="this.$store.getters.user == 'Super_Admin' && AssociateShow"
      class="container"
    >
      <h4 style="text-align: center; padding: 30px">Registered Associate's</h4>
      <div class="row g-3 justify-content-center align-items-center">
        <div class="col-lg-10">
          <div class="form-input-dropdown position-relative">
            <input
              v-model="AgentSearch"
              placeholder="Search using Mobile Number"
              class="form-control form-control-lg me-1 ps-5"
              type="text"
            />
          </div>
        </div>
        <div class="col-lg-2 d-grid">
          <a
            class="btn btn-lg btn-primary mb-0"
            href="#"
            @click.prevent="getAssociateList"
            >Search</a
          >
        </div>
      </div>
      <section>
        <div class="table-responsive border-0">
          <table class="table align-middle p-4 mb-0 table-hover table-shrink">
            <thead class="table-light">
              <tr>
                <th scope="col" class="border-0 rounded-start">#</th>
                <th scope="col" class="border-0">Name</th>
                <th scope="col" class="border-0">Mobile Number</th>
                <th scope="col" class="border-0">Total Alloted Cards</th>
                <th scope="col" class="border-0">Assigned cards to members</th>
                <th scope="col" class="border-0">Pending Cards</th>
                <th scope="col" class="border-0">Action</th>
              </tr>
            </thead>
            <tbody class="border-top-0">
              <tr v-for="(AgentItem, key) in TotalAgentsList" :key="key">
                <td>
                  <h6 class="mb-0">{{ key + 1 }}</h6>
                </td>
                <td>
                  {{ AgentItem.name }}
                </td>
                <td>
                  {{ AgentItem.mobile_number }}
                </td>
                <td>
                  {{ AgentItem?.agent_card?.length }}
                </td>
                <td>
                  {{ AgentItem?.assigned_cards }}
                </td>
                <td>
                  {{
                    AgentItem?.agent_card?.length - AgentItem?.assigned_cards
                  }}
                </td>
                <td>
                  <button class="btn btn-primary" @click="showPopup(AgentItem)">
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <!-- Agent List -->
    <div v-if="this.$store.getters.user == 'Associate'">
      <br />
      <section
        v-if="
          panCheck !== null &&
          nameCheck !== '' &&
          agent_imagesCheck.length !== 0
        "
      >
        <h3 style="text-align: center">MY CARDS</h3>
        <br />
        <div class="container">
          <h6>Total cards Alloted : {{ cardsLength }}</h6>
          <div class="table-responsive border-0">
            <table class="table align-middle p-4 mb-0 table-hover table-shrink">
              <thead class="table-light">
                <tr>
                  <th scope="col" class="border-0 rounded-start">#</th>
                  <th scope="col" class="border-0">Card Number</th>
                  <th scope="col" class="border-0">Member Name</th>
                  <th scope="col" class="border-0">Allocate To New Member</th>
                  <th scope="col" class="border-0">
                    Allocate To Existing Member
                  </th>
                  <!-- <th scope="col" class="border-0">Action</th> -->
                </tr>
              </thead>
              <tbody class="border-top-0">
                <tr v-for="(card, key) in cards" :key="key">
                  <td>
                    <h6 class="mb-0">{{ key + 1 }}</h6>
                  </td>
                  <td>{{ card.card_no }}</td>
                  <td>
                    <span v-if="card?.member != null">
                      {{ card?.member?.name }}
                    </span>
                    <span v-if="card?.member == null">Assign Member</span>
                  </td>

                  <td>
                    <button
                      class="btn btn-primary"
                      v-if="card.member == null"
                      @click="addMemberCard(card.id, 'false')"
                    >
                      New Member
                    </button>
                    <h4 v-if="card.member !== null">-</h4>
                  </td>
                  <!-- <img src="@/assets/banners/checked.png" /> -->

                  <td>
                    <button
                      @click="addMemberCard(card.id, 'true')"
                      v-if="card.member == null"
                      class="btn btn-primary"
                    >
                      Existing Member
                    </button>
                    <h4 v-if="card.member !== null">-</h4>
                  </td>
                  <!-- <td>
                    <button
                      @click.prevent="viewMemberDetails()"
                      class="btn btn-primary"
                    >
                      View
                    </button>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
    <div v-if="this.$store.getters.user == 'Admin'" class="container">
      <br />
      <h4 style="text-align: center; padding: 20px">
        ASSOCIATE&nbsp;&nbsp;LIST
      </h4>
      <section>
        <div class="table-responsive border-0">
          <h6 v-if="AgentList.length == 0" style="text-align: center">
            No Associate Found.
          </h6>
          <h6>Total cards Alloted: &nbsp;{{ TotalCount }}</h6>
          <h6>Pending cards : &nbsp;{{ TotalCount - PendingCount }}</h6>
          <button @click="ViewAllDis(totalDistCards)" class="btn btn-primary">
            View All Cards
          </button>

          <table
            v-if="AgentList.length !== 0"
            class="table align-middle p-4 mb-0 table-hover table-shrink"
          >
            <thead class="table-light">
              <tr>
                <th scope="col" class="border-0 rounded-start">#</th>
                <th scope="col" class="border-0">Name</th>
                <th scope="col" class="border-0">Mobile Number</th>
                <th scope="col" class="border-0">Cards Assigned</th>
                <th scope="col" class="border-0">Add More Cards</th>
                <th scope="col" class="border-0">Members Detail</th>
              </tr>
            </thead>
            <tbody class="border-top-0">
              <tr v-for="(booking, key) in AgentList" :key="key">
                <td>
                  <h6 class="mb-0">{{ key + 1 }}</h6>
                </td>
                <td>
                  {{ booking.name }}
                </td>
                <td>
                  {{ booking.mobile_number }}
                </td>
                <td>
                  {{ booking.agent_card.length }}
                </td>
                <td>
                  <button
                    class="btn btn-primary"
                    @click="AddMoreCards(booking.id)"
                  >
                    Add Cards
                  </button>
                </td>
                <td>
                  <button
                    class="btn btn-primary"
                    @click="ViewAllDistributorCards(booking.agent_card)"
                  >
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <!--  -->
    <div class="container">
      <dialog id="showDissMore" class="daialbox">
        <div class="table-responsive border-0">
          <div>
            <i
              class="fas fa-times-circle"
              style="
                font-size: 20px;
                color: rgb(42, 38, 38);
                float: right;
                cursor: pointer;
              "
              v-on:click="closeshowDissMore()"
            ></i>
          </div>
          <br />
          <br />
          <table class="table align-middle p-4 mb-0 table-hover table-shrink">
            <thead class="table-light">
              <tr>
                <th scope="col" class="border-0 rounded-start">#</th>
                <th scope="col" class="border-0">Card Number</th>
                <th scope="col" class="border-0">Assigned to Associate</th>
              </tr>
            </thead>

            <tbody class="border-top-0">
              <tr
                v-for="(item, index) in allAllotedcloseshowDissMore"
                :key="key"
              >
                <td>
                  <h6 class="mb-0">{{ index + 1 }}</h6>
                </td>
                <td>
                  {{ item.card_no }}
                </td>
                <td v-if="item.agent !== null">
                  <img src="@/assets/banners/checked.png" />
                </td>
                <td v-if="item?.agent == null">
                  <img src="@/assets/banners/delete.png" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </dialog>
    </div>
    <!--  -->
    <div class="container">
      <dialog id="showCardsMore" class="daialbox">
        <div class="table-responsive border-0">
          <div>
            <i
              class="fas fa-times-circle"
              style="
                font-size: 20px;
                color: rgb(42, 38, 38);
                float: right;
                cursor: pointer;
              "
              v-on:click="closeCardsMore()"
            ></i>
          </div>
          <br />
          <br />
          <table class="table align-middle p-4 mb-0 table-hover table-shrink">
            <thead class="table-light">
              <tr>
                <th scope="col" class="border-0 rounded-start">#</th>
                <th scope="col" class="border-0">Card Number</th>
                <th scope="col" class="border-0">Assigned to Member</th>
                <th scope="col" class="border-0">Member Name</th>
                <th scope="col" class="border-0">Payment Status</th>
                <th scope="col" class="border-0">Surrender Card</th>
              </tr>
            </thead>

            <tbody class="border-top-0">
              <tr v-for="(item, index) in allAllotedCards" :key="key">
                <td>
                  <h6 class="mb-0">{{ index + 1 }}</h6>
                </td>
                <td>
                  {{ item.card_no }}
                </td>
                <td v-if="item?.member !== null">
                  <img src="@/assets/banners/checked.png" />
                </td>
                <td v-if="item?.member === null">
                  <img src="@/assets/banners/delete.png" />
                </td>
                <td v-if="item?.member !== null">
                  {{ item?.member?.name }} ({{ item?.member?.mobile_number }})
                </td>
                <td v-if="item?.member === null">-</td>

                <td v-if="item?.member !== null">
                  <button
                    class="btn btn-primary"
                    @click="viewPaymentsMemebers(item?.member?.member_payment)"
                  >
                    View
                  </button>
                </td>
                <td v-if="item?.member === null">-</td>
                <td v-if="item?.member === null">
                  <button
                    class="btn btn-primary"
                    @click="deleteCards(item?.id)"
                  >
                    Delete
                  </button>
                </td>
                <td v-if="item?.member !== null">-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </dialog>
    </div>
    <!--  -->
    <!--  -->
    <div class="container">
      <dialog id="showCards" class="daialbox">
        <div class="table-responsive border-0">
          <div>
            <i
              class="fas fa-times-circle"
              style="
                font-size: 20px;
                color: rgb(42, 38, 38);
                float: right;
                cursor: pointer;
              "
              v-on:click="closeCards()"
            ></i>
          </div>
          <div v-if="totalEmptyCards.length == 0" style="align-items: center">
            <br />
            <br />
            <h6 style="text-align: center; padding: 10px; margin: 10px">
              No Cards Found
            </h6>
            <br />
            <br />
          </div>
          <table
            v-if="totalEmptyCards.length !== 0"
            class="table align-middle p-4 mb-0 table-hover table-shrink"
          >
            <thead class="table-light">
              <tr>
                <th scope="col" class="border-0 rounded-start">#</th>
                <th scope="col" class="border-0">Card Number</th>
                <th scope="col" class="border-0">Action</th>
              </tr>
            </thead>

            <tbody class="border-top-0">
              <tr v-for="(item, index) in totalEmptyCards" :key="key">
                <td>
                  <h6 class="mb-0">{{ index + 1 }}</h6>
                </td>
                <td>
                  {{ item.card_no }}
                </td>
                <td>
                  <input
                    type="checkbox"
                    :id="'checkbox-' + index"
                    :value="item.id"
                    v-model="selectedItems"
                    @change="saveSelection"
                  />
                  <label :for="'checkbox-' + index">SELECT</label>
                </td>
              </tr>
            </tbody>
          </table>
          <button
            v-if="totalEmptyCards.length !== 0"
            class="btn btn-primary"
            @click="AddMoreNewCards()"
          >
            Submit
          </button>
        </div>
      </dialog>
    </div>
    <!--  -->
    <!--  -->
    <div class="container">
      <dialog id="pendingPayments" class="daialbox">
        <div class="table-responsive border-0">
          <div>
            <i
              class="fas fa-times-circle"
              style="
                font-size: 20px;
                color: rgb(42, 38, 38);
                float: right;
                cursor: pointer;
              "
              v-on:click="closePaymentModal()"
            ></i>
          </div>
          <br />
          <br />

          <table class="table align-middle p-4 mb-0 table-hover table-shrink">
            <thead class="table-light">
              <tr>
                <th scope="col" class="border-0 rounded-start">#</th>
                <th scope="col" class="border-0">Amount</th>
                <th scope="col" class="border-0">Payment Due Date</th>
                <th scope="col" class="border-0">Payment Status</th>
              </tr>
            </thead>
            <tbody class="border-top-0">
              <tr v-for="(booking, key) in paymentDetails" :key="key">
                <td>
                  <span class="mb-0">{{ key + 1 }}</span>
                </td>
                <td>
                  <span class="mb-0">{{ booking.amount }}</span>
                </td>
                <td>
                  <h6>
                    {{ moment(booking.payment_due_date).format("MMMM Do") }}
                  </h6>
                </td>
                <td v-if="booking.payment_status == true">
                  <img src="@/assets/banners/checked.png" />
                </td>
                <td v-if="booking.payment_status == false">
                  <img src="@/assets/banners/delete.png" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </dialog>
    </div>
    <!--  -->
    <!--  -->
  </main>
</template>
<script>
import Paginate from "@/components/Pagination.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import ExcelJS from "exceljs";
var moment = require("moment");
export default {
  name: "Home",
  components: {
    Paginate,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      moment: moment,
      selectedItem: null,
      cards: [],
      TotalRegMember: "",
      TotalMemberList: "",
      excel_data: "",
      totalEmptyCards: "",
      panCheck: "",
      nameCheck: "",
      agent_imagesCheck: "",
      newdata: "",
      showAgentUpdate: false,
      selectedImageFile: "",
      sendImage: {
        image: "",
      },
      agentDeTails: {
        village: "",
        taluk: "",
        district: "",
        state: "",
        occupation: "",
        address: "",
        agent_images: [],
      },
      image_Id: [],
      Village: "",
      Taluk: "",
      District: "",
      State: "",
      Occupation: "",
      Address: "",
      panImage: "",
      aadharImage: "",
      errorVillage: "",
      errorTaluk: "",
      errorDistrict: "",
      errorState: "",
      errorOccupation: "",
      errorAddress: "",
      errorpanImage: "",
      erroraadharImage: "",
      errorqr_code: "",
      errorImage1: "",
      errorImage2: "",
      agentName: "",
      QrCodeImage: "",
      AgentList: "",
      DistrubtorShow: false,
      AssociateShow: false,
      MemberShow: false,
      page: 1,
      size: 10,
      count: 0,
      maxPage: 1,
      lastPage: 1,
      search: "",
      memberSearch: "",
      AgentSearch: "",
      ordering: "",
      TotalAgentsList: "",
      CardsAgentname: "",
      CardsAgentoccupation: "",
      CardsAgentstate: "",
      CardsAgentdistrict: "",
      CardsAgenttaluk: "",
      CardsAgentvillage: "",
      member_id: "",
      paymentDetails: "",
      TotalCount: "",
      PendingCount: "",
      totalDistCards: "",
      allAllotedCards: "",
      allAllotedcloseshowDissMore: "",
      cardsLength: "",
      selectedCard: null,
      selectedItems: [],
      isTenthOfMonth: false,
      member_id: "",
      member_mobile_number: "",
      member_name: "",
      card_card_no: "",
      member_agent_name: "",
      member_agent_mobile_number: "",
      member_agent_state: "",
      member_agent_district: "",
      member_agent_taluk: "",
      member_agent_village: "",
      member_agent_address: "",
      member_agent_image: "",
      member_agent_qr_code: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);

    if (this.$store.getters.user == "Super_Admin") {
      this.getRegisteredMem();
      this.getAllMemberList();
      this.getAssociateList();
    }
    if (this.$store.getters.user == "Associate") {
      this.getAgentDetails();
      this.getAgentCards();
      this.getCard();
      const currentDate = moment();
      this.isTenthOfMonth = currentDate.date() === 10;
      if (this.isTenthOfMonth) {
        this.getPendingPayments();
      }
    }
    if (this.$store.getters.user == "Admin") {
      this.getAgentList();
      this.gettotalCount();
    }
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  props: {
    membersData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async generateExcel(membersData) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Members");

      // Define headers for member and payment data
      worksheet.addRow([
        "Member ID",
        "Mobile Number",
        "Name",
        "Card Number",
        "Agent Name",
        "Agent Mobile Number",
        "Agent State",
        "Agent District",
        "Agent Taluk",
        "Agent Village",
        "Agent Address",
        "Agent Image",
        "Agent Qr Code",
        "Payment ID",
        "Amount",
        "Due Date",
        "Status",
        "Receipt No",
        "Payment Date",
        "Payment Mode",
        "Place",
        "Payment Image",
      ]);

      // Iterate through members
      for (let i = 0; i < membersData.length; i++) {
        let member = membersData[i];
        console.log("member", member);
        this.member_id = member.id;
        this.member_mobile_number = member.mobile_number;
        this.member_name = member.name;
        for (let j = 0; j < member.member_card.length; j++) {
          let card = member.member_card[j];
          this.card_card_no = card.card_no;
          this.member_agent_name = card.agent.name;
          this.member_agent_mobile_number = card.agent.mobile_number;
          this.member_agent_state = card.agent.state;
          this.member_agent_district = card.agent.district;
          this.member_agent_taluk = card.agent.taluk;
          this.member_agent_village = card.agent.village;
          this.member_agent_address = card.agent.address;
          this.member_agent_image = card.agent.agent_images[0].image;
          this.member_agent_qr_code = card.agent.qr_code;
          console.log("card", card);
          for (let k = 0; k < card.card_payment.length; k++) {
            let payment = card.card_payment[k];
            console.log("payment", payment);
            // Add rows for each payment
            worksheet.addRow([
              this.member_id,
              this.member_mobile_number,
              this.member_name,
              this.card_card_no,
              this.member_agent_name,
              this.member_agent_mobile_number,
              this.member_agent_state,
              this.member_agent_district,
              this.member_agent_taluk,
              this.member_agent_village,
              this.member_agent_address,
              this.member_agent_image,
              this.member_agent_qr_code,
              payment.id,
              payment.amount,
              payment.payment_due_date,
              payment.payment_status ? "Paid" : "Unpaid",
              payment.receipt_no || "",
              payment.payment_datetime || "",
              payment.payment_mode || "",
              payment.place || "",
              payment.payment_image || "",
            ]);
          }
        }
      }

      // Save Excel file
      const buffer = await workbook.xlsx.writeBuffer();
      this.downloadExcel(buffer);
    },
    downloadExcel(buffer) {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "members_data.xlsx";
      link.click();
    },
    getPendingPayments() {
      let vm = this;
      vm.$store
        .dispatch("get", {
          uri: "administrator/check_missed_payments/",
        })
        .then((response) => {
          console.log("getPendingPayments", response);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    viewPaymentsMemebers(Payments) {
      document.getElementById("pendingPayments").showModal();
      this.paymentDetails = Payments;
      console.log(Payments);
    },
    gettotalCount() {
      let vm = this;
      vm.$store
        .dispatch("get", {
          uri: "administrator/distributer/card-count",
        })
        .then((response) => {
          console.log("TotalCount", response);
          this.TotalCount = response?.data?.total_distributer_cards;
          this.PendingCount = response?.data?.total_agent_cards;
          this.totalDistCards = response?.data?.cards;
          this.allAllotedCards = this.totalDistCards;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    closePaymentModal() {
      document.getElementById("pendingPayments").close();
    },
    AddMoreNewCards() {
      let vm = this;
      let loader = vm.$loading.show();
      vm.$store
        .dispatch("post", {
          uri: "administrator/agent/assign-cards/",
          data: {
            user_id: this.member_id,
            card_ids: JSON.stringify(this.selectedItems),
          },
        })
        .then((response) => {
          loader.hide();
          document.getElementById("showCards").close();
          this.getAgentList();
          console.log("AddedNew", response);
        })
        .catch((error) => {
          loader.hide();
          console.log(error);
        });
    },
    viewPayments(id, card) {
      let vm = this;
      vm.$store
        .dispatch("get", {
          uri:
            "administrator/member/payment-list/?card_id=" +
            card +
            "&member_id=" +
            id +
            "",
        })
        .then((response) => {
          console.log(response);
          document.getElementById("pendingPayments").showModal();
          this.paymentDetails = response.data;
          console.log("paymentDetails", this.paymentDetails);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    closeCards() {
      document.getElementById("showCards").close();
    },
    closeCardsMore() {
      document.getElementById("showCardsMore").close();
    },
    closeshowDissMore() {
      document.getElementById("showDissMore").close();
    },
    saveSelection() {
      localStorage.setItem("selectedItems", JSON.stringify(this.selectedItems));
      console.log(JSON.stringify(this.selectedItems));
    },
    getCard() {
      let vm = this;
      vm.$store
        .dispatch("get", { uri: "administrator/agent/empty-cards/" })
        .then((response) => {
          console.log(response);
          this.totalEmptyCards = response.data;
          console.log("this.totalEmptyCards", this.totalEmptyCards);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    ViewAllDis(Cards) {
      document.getElementById("showDissMore").showModal();
      this.allAllotedcloseshowDissMore = Cards;
    },
    ViewAllDistributorCards(Cards) {
      document.getElementById("showCardsMore").showModal();
      this.allAllotedCards = Cards;
      console.log(this.allAllotedCards);
    },
    AddMoreCards(id) {
      this.member_id = id;
      this.selectedItems = [];
      document.getElementById("showCards").showModal();
      this.getCard();
    },
    deleteCards(id) {
      let vm = this;
      let loader = vm.$loading.show();
      vm.$store
        .dispatch("post", {
          uri: "administrator/update-card/" + id + "/",
          data: { field: "agent" },
        })
        .then((response) => {
          loader.hide();
          document.getElementById("dialogCard").close();
          document.getElementById("showCardsMore").close();
          this.getAssociateList();
          this.getAgentList();
          this.gettotalCount();
          console.log("deleted", response);
        })
        .catch((error) => {
          loader.hide();
          console.log(error);
        });
    },
    showPopup(item) {
      this.CardsAgentname = item.name;
      this.CardsAgentoccupation = item.occupation;
      this.CardsAgentstate = item.state;
      this.CardsAgentdistrict = item.district;
      this.CardsAgenttaluk = item.taluk;
      this.CardsAgentvillage = item.village;
      this.selectedItem = item.agent_card;
      console.log("item", item);
      document.getElementById("dialogCard").showModal();
    },
    closeAgentsCard() {
      document.getElementById("dialogCard").close();
      this.selectedItem = null;
    },
    getAssociateList() {
      let vm = this;
      let url = "";
      console.log("this.AgentSearch", this.AgentSearch);
      if (this.AgentSearch.length !== 0) {
        url =
          "administrator/all-agents/?page=1&size=1&search=%2B91" +
          this.AgentSearch;
      } else {
        url = "administrator/all-agents/";
      }
      vm.$store
        .dispatch("get", { uri: url })
        .then((response) => {
          if (this.AgentSearch.length !== 0) {
            this.TotalAgentsList = response?.data?.results;
          } else {
            this.TotalAgentsList = response?.data;
          }
          console.log("TotalAgentsList", this.TotalAgentsList);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    ExportAll() {
      this.generateExcel(this.excel_data);
    },
    getAllMemberList() {
      let vm = this;
      let url = "";
      if (this.memberSearch.length !== 0) {
        url =
          "administrator/all-members/?page=1&size=1&search=%2B91" +
          this.memberSearch;
      } else {
        url = "administrator/all-members/";
      }
      vm.$store
        .dispatch("get", { uri: url })
        .then((response) => {
          this.excel_data = response?.data;
          if (this.memberSearch.length !== 0) {
            this.TotalMemberList = response?.data?.results;
          } else {
            this.TotalMemberList = response?.data;
          }
          console.log("this.TotalMemberList", this.TotalMemberList);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    memberShow() {
      this.DistrubtorShow = false;
      this.AssociateShow = false;
      this.MemberShow = true;
    },
    associateShow() {
      this.DistrubtorShow = false;
      this.AssociateShow = true;
      this.MemberShow = false;
    },
    distShow() {
      this.DistrubtorShow = true;
      this.AssociateShow = false;
      this.MemberShow = false;
    },
    getAgentList() {
      let vm = this;
      vm.$store
        .dispatch("get", { uri: "administrator/agents" })
        .then((response) => {
          this.AgentList = response?.data;
          console.log("cardsRESPONSE", response);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    viewMemberDetails() {
      this.$router.push({
        name: "Member",
        params: {
          CardId: 0,
        },
      });
    },
    getQrCode(event) {
      let QRImage = event.target.files[0];
      this.QrCodeImage = QRImage;
    },
    addMemberCard(id, ExMem) {
      if (ExMem == "true") {
        localStorage.setItem("ExistingUser", ExMem);
      } else {
        localStorage.setItem("ExistingUser", ExMem);
      }
      this.$router.push({
        name: "Member",
        params: {
          CardId: id,
        },
      });
    },
    showDetails(index) {
      this.cards[index].showDetails = true;
    },
    hideDetails() {
      this.cards.forEach((card) => {
        card.showDetails = false;
      });
    },
    getAgentCards() {
      let vm = this;
      vm.$store
        .dispatch("get", { uri: "administrator/agent/cards/" })
        .then((response) => {
          this.cards = response?.data?.cards;
          this.agentName = response?.data?.name;
          this.cardsLength = response?.data?.cards.length;
          console.log("cards", this.cardsLength);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    SubmitAgentDetails() {
      let vm = this;
      vm.errorVillage = "";
      vm.errorTaluk = "";
      vm.errorDistrict = "";
      vm.errorState = "";
      vm.errorOccupation = "";
      vm.errorpanImage = "";
      vm.erroraadharImage = "";
      vm.errorqr_code = "";
      vm.errorImage1 = "";
      vm.errorImage2 = "";
      if (this.Taluk == "") {
        this.errorTaluk = "Please Enter Taluk";
      } else if (this.District == "") {
        this.errorDistrict = "Please Enter District";
      } else if (this.QrCodeImage == "") {
        this.errorqr_code = "Please Upload UPI QR Code Image";
      } else if (this.aadharImage == "") {
        this.erroraadharImage = "Please Upload Aadhar Image";
      } else if (this.image_Id.length == 0) {
        this.errorImage1 = "Please Upload Your Selfie Image";
      } else {
        this.agentDeTails.village = this.Village;
        this.agentDeTails.taluk = this.Taluk;
        this.agentDeTails.district = this.District;
        this.agentDeTails.state = this.State;
        this.agentDeTails.occupation = this.Occupation;
        this.agentDeTails.address = this.Address;
        this.agentDeTails.agent_images = this.image_Id;
        console.log(" this.agentDeTails", this.agentDeTails);
        let loader = this.$loading.show();
        this.$store
          .dispatch("put", {
            uri: "administrator/agent/profile-update/",
            data: this.agentDeTails,
          })
          .then((response) => {
            loader.hide();
            this.getAgentDetails();
          })
          .catch((errors) => {
            loader.hide();
            console.log(errors);
          });
      }
    },
    getAadhar(event) {
      this.errorpanImage = "";
      this.erroraadharImage = "";
      this.errorqr_code = "";
      let AadharImage = event.target.files[0];
      this.aadharImage = AadharImage;
      if (this.aadharImage == "") {
        this.erroraadharImage = "Please Upload Aadhar Image";
      } else if (this.QrCodeImage == "") {
        this.errorqr_code = "Please Upload QR Code Image";
      } else {
        const formData = new FormData();
        formData.append("pan_card", this.panImage);
        formData.append("adhar_card", this.aadharImage);
        formData.append("qr_code", this.QrCodeImage);
        console.log(" formData", formData);

        let loader = this.$loading.show();
        this.$store
          .dispatch("putAgent", {
            uri: "administrator/agent/kyc-update/",
            data: formData,
          })
          .then((response) => {
            loader.hide();
            console.log(response);
          })
          .catch((errors) => {
            loader.hide();
            console.log(errors);
          });
      }
    },
    getPan(event) {
      let PanImage = event.target.files[0];
      this.panImage = PanImage;
    },
    handleImageChange(event) {
      let vm = this;
      this.errorImage1 = "";
      const formData = new FormData();
      const selectedImageFile = event.target.files[0];
      formData.append("image", selectedImageFile);

      let loader = this.$loading.show();
      this.$store
        .dispatch("postWinner", {
          uri: "administrator/agent/create-images/",
          data: formData,
        })
        .then((response) => {
          loader.hide();
          const newNumber = Math.floor(response.data.id);
          console.log("newNumber", newNumber);
          this.image_Id.push(newNumber);
          console.log(this.image_Id);
        })
        .catch((errors) => {
          loader.hide();
          this.errorImage1 = errors?.response?.data?.image[0];
          console.log(errors?.response?.data);
        });
    },
    addAgentImage() {
      let vm = this;
      const formData = new FormData();
      formData.append("image", this.selectedImageFile);
      for (const entry of formData.entries()) {
        console.log(entry);
      }
      let loader = this.$loading.show();
      this.$store
        .dispatch("postWinner", {
          uri: "administrator/agent/create-images/",
          data: formData,
        })
        .then((response) => {
          loader.hide();
        })
        .catch((errors) => {
          loader.hide();
          console.log(errors);
        });
    },
    getAgentDetails() {
      let vm = this;
      vm.$store
        .dispatch("get", { uri: "administrator/agent/profile/" })
        .then((response) => {
          this.showAgentUpdate = true;
          this.panCheck = response?.data.qr_code;
          this.nameCheck = response?.data.name;
          this.agent_imagesCheck = response?.data.agent_images;
          console.log("response?.pan_card", response?.data);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    assignCards(id) {
      this.$router.push({
        name: "AssignCards",
        params: {
          Dist: id,
        },
      });
    },
    getRegisteredMem() {
      let vm = this;
      vm.$store
        .dispatch("get", { uri: "administrator/distributers/" })
        .then((response) => {
          this.DistrubtorShow = true;
          this.TotalRegMember = response?.data;
          console.log("this.TotalRegMember", this.TotalRegMember);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>
<style>
.temple {
  border-radius: 15px;
  object-fit: cover;
  width: 100%;
  height: 250px;
}
.no-padding {
  background-color: transparent;
  margin-top: -70px;
}
@media only screen and (max-width: 375px) {
  .carousel__slide .carousel__slide--visible .carousel__slide--active {
    width: 100% !important;
  }
  .carousel__slide
    .carousel__slide--clone
    .carousel__slide--visible
    .carousel__slide--prev {
    width: 100% !important;
  }
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .carousel-caption {
    top: 300px;
    text-align: left;
    background-color: transparent;
    padding: 35px;
    width: fit-content;
  }
  .carousel-item.active {
    display: block;
  }
  .ba-1 {
    background-size: cover;
    background-position: top left;
  }
}
.imagetext {
  top: 150px;
  float: left;
}
.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background: white;
  padding: 20px;
  border-radius: 8px;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blue-btn {
  background: #0047ba;
  border: 1px solid #0047ba;
  border-radius: 4px;
  color: #ffffff;
  transition: all 0.3s linear 0s;
  font-size: 15px;
  height: 40px;
  padding: 5px 50px;
  box-sizing: border-box;
  font-weight: 500;
  letter-spacing: 1px;
}
/* event */
/* Event New */
@media (min-width: 992px) {
  .col-lg-6 {
    color: transparent;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 768px) {
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
.carousel-container * {
  box-sizing: border-box;
}
.carousel-caption-custom-1 {
  padding: 12px 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  z-index: 10;
  color: #fff;
  text-align: center;
  position: absolute;
  bottom: 7%;
  width: 88%;
  border-top: 1px #fff solid;
}

.addwrap {
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.text-left {
  text-align: left !important;
}
@media (min-width: 768px) {
  .titletext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 110%;
    color: #ffffff;
  }
}
@media (min-width: 940px) {
  .titletext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 110%;
    color: #ffffff;
  }
}
.titletext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  color: #ffffff;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
@media (min-width: 768px) {
  .subtitletext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    color: #ffffff;
  }
}
@media (min-width: 940px) {
  .subtitletext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    color: #ffffff;
  }
}
.subtitletext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.participatebuttonclass {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
}

.responsive-section {
  display: flex;
  flex-wrap: wrap;
}

.div1 {
  order: 1; /* Initially div1 */
}

.div2 {
  order: 2; /* Initially div2 */
}

@media (min-width: 1200px) {
  .box {
    flex: 1;
  }
}

@media (max-width: 1200px) {
  .div1 {
    order: 1;
  }
  .div2 {
    order: 2;
  }
}

.box {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.left-box {
  order: 1; /* Initially left box */
}

.right-box {
  order: 2; /* Initially right box */
}

img {
  max-width: 100%;
  height: auto;
}
/*  */
.app-info-section {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
}

.app-info {
  flex: 1;
  min-width: 200px; /* Set a minimum width for better responsiveness */
  padding: 20px;
  background-color: #f0f0f0;
}

.divider-section {
  flex: 1;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
}

.divider-item {
  flex: 1;
  min-width: 150px; /* Set a minimum width for better responsiveness */
  padding: 20px;
  border: 1px solid #ccc;
  margin: 10px; /* Add margin for separation */
}
/*  */
.app-info-section {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
}

.app-info {
  flex: 1;
  min-width: 200px; /* Set a minimum width for better responsiveness */
  padding: 20px;
  background-color: #f0f0f0;
}

.divider-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.divider-row {
  display: flex;
  flex: 1;
}

.divider-item {
  flex: 1;
  min-width: 150px;
  padding: 20px;
  border: 0px;
  margin: 10px;
}
.rolling-number {
  font-size: 24px;
  display: inline-flex;
}

.digit {
  transition: transform 0.1s ease-in-out;
  display: inline-block;
}

@media (min-width: 992px) {
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 768px) {
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.w-100 {
  width: 100% !important;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
img {
  vertical-align: middle;
  border-style: none;
}
.carousel-caption-custom-1 {
  padding: 12px 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  z-index: 10;
  color: #fff;
  text-align: center;
  position: absolute;
  bottom: 7%;
  width: 88%;
  border-top: 1px #fff solid;
}
.addwrap {
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
/*  */
@media (min-width: 768px) {
  .titletext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 110%;
    color: #ffffff;
  }
}
@media (min-width: 940px) {
  .titletext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 110%;
    color: #ffffff;
  }
}
.titletext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  color: #ffffff;
}
.card-hover {
  position: relative;
  overflow: hidden;
  /* width: 200px; */
  height: 300px;
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: transform 0.3s;
}

.card-hover:hover {
  transform: scale(1.3);
}

.card-hover-image {
  align-items: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-hover-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s, transform 0.3s;
}

.card-hover:hover .card-hover-popup {
  opacity: 1;
  transform: translateY(0);
}
/* Style your modal as desired */
.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
/*  */
/* event */
</style>
