<template>
  <main>
    <section>
      <div class="container">
        <div v-if="cardIdAssign !== '0'" class="card-body">
          <div v-if="IsUserExist !== 'true'" class="mb-2">
            <label class="form-label">
              Name <span class="text-red" style="color: red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Member Name"
              v-model="member_name"
            />
            <span v-if="nameMemberError != ''" style="color: red">{{
              nameMemberError
            }}</span>
          </div>
          <div v-if="IsUserExist !== 'true'" class="mb-2">
            <label class="form-label"
              >Mobile Number <span class="text-red" style="color: red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Member Number"
              v-model="member_numberss"
            />
          </div>
          <span v-if="mobileMemberError != ''" style="color: red">{{
            mobileMemberError
          }}</span>
          <!--  -->
          <div v-if="IsUserExist !== 'true'" class="mb-2">
            <label class="form-label">Alternate Mobile Number </label>
            <input
              type="text"
              class="form-control"
              placeholder="Alternate Mobile Number"
              v-model="alternate_mobile_number"
            />
          </div>
          <div v-if="IsUserExist !== 'true'" class="mb-2">
            <label class="form-label">Address </label>
            <input
              type="text"
              class="form-control"
              placeholder="Address"
              v-model="address"
            />
          </div>
          <div v-if="IsUserExist == 'true'" class="mb-2">
            <label class="form-label"
              >Assign Member <span class="text-red" style="color: red">*</span>
            </label>
            <select
              class="form-control"
              v-model="selMemberCard"
              @change="handleMember"
            >
              <option
                v-for="card in total_memCards"
                :key="card.id"
                :value="card.id"
              >
                Name: {{ card.name }} , Mobile Number:
                {{ card.mobile_number }}
                <hr />
              </option>
            </select>
            <span v-if="assignMemberError != ''" style="color: red">{{
              assignMemberError
            }}</span>
          </div>

          <div v-if="IsUserExist !== 'true'" class="mb-2">
            <label class="form-label"
              >Select Plan<span class="text-red" style="color: red">*</span>
            </label>
            <select class="form-control" v-model="selMemPlan">
              <option v-for="card in mem_Plans" :key="card.id" :value="card.id">
                Name: {{ card.plan_name }} , Plan Amount: {{ card.plan_amount }}
                <hr />
              </option>
            </select>
            <span v-if="planMemberError != ''" style="color: red">{{
              planMemberError
            }}</span>
          </div>
          <!--  -->
          <div class="mb-2">
            <label class="form-label"
              >Card Image: <span class="text-red" style="color: red">*</span>
            </label>
            <input
              type="file"
              ref="qrcode"
              @change="getCardImage"
              accept="image/*"
              class="form-control"
            />
          </div>
          <span v-if="errorqr_code != ''" style="color: red">{{
            errorqr_code
          }}</span>
          <!--  -->
          <div>
            <button class="btn btn-primary" @click="addMemDetails">
              Submit
            </button>
          </div>
          <!--  -->
        </div>
        <br />
      </div>
      <div class="container">
        <div class="card-body" v-if="PaymentModeCheck">
          <h3>Add Payment Details Here :</h3>
          <div class="mb-2">
            <label class="form-label"
              >Enter Place :<span class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Place Of Payment"
              v-model="postPayment.place"
            />
          </div>
          <div class="mb-2">
            <label class="form-label"
              >Select Payment Mode :<span class="text-red">*</span>
            </label>
            <select class="form-control" v-model="postPayment.payment_mode">
              <option value="">Select Payment Mode</option>
              <hr />
              <option value="UPI">UPI</option>
              <hr />
              <option value="CASH">CASH</option>
              <hr />
              <option value="NETBANKING">NetBanking</option>
            </select>
          </div>
          <div class="mb-2">
            <span v-if="paymentError != ''" style="color: red">{{
              paymentError
            }}</span>
          </div>
          <button class="btn btn-primary" @click="makePayment">Submit</button>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        <br />
      </div>
      <div class="container">
        <h3 style="text-align: center">MEMBER'S LIST</h3>
        <div class="row g-3 justify-content-center align-items-center">
          <div class="col-lg-10">
            <div class="form-input-dropdown position-relative">
              <input
                v-model="meta.search"
                placeholder="Search using Mobile Number"
                class="form-control form-control-lg me-1 ps-5"
                type="text"
              />
            </div>
          </div>
          <div class="col-lg-2 d-grid">
            <a
              class="btn btn-lg btn-primary mb-0"
              href="#"
              @click.prevent="getMemberList"
              >Search</a
            >
          </div>
        </div>
      </div>
      <!--  -->
      <br />
      <br />
      <div class="container">
        <div class="card-body">
          <div class="table-responsive border-0">
            <h6 v-if="totalMemberList.length == 0" style="text-align: center">
              No Detail's Found.
            </h6>
            <table
              v-if="totalMemberList.length !== 0"
              class="table align-middle p-4 mb-0 table-hover table-shrink"
            >
              <thead class="table-light">
                <tr>
                  <th scope="col" class="border-0 rounded-start">#</th>
                  <th scope="col" class="border-0">NAME</th>
                  <th scope="col" class="border-0">MOBILE NO</th>
                  <th scope="col" class="border-0">MAKE PAYMENT</th>
                </tr>
              </thead>
              <tbody class="border-top-0">
                <tr v-for="(booking, key) in totalMemberList" :key="key">
                  <td>
                    <h6 class="mb-0">{{ key + 1 }}</h6>
                  </td>
                  <td>{{ booking.name }}</td>
                  <td>{{ booking.mobile_number }}</td>
                  <td>
                    <h6 class="mb-0 fw-light">
                      <button
                        style="
                          border-radius: 5px;
                          background-color: rgb(112 216 184);
                        "
                        @click="
                          cardDeatils(
                            booking.member_card[0]?.card_no,
                            booking.member_card[0]?.card_payment,
                            booking.is_active,
                            booking.id
                          )
                        "
                      >
                        {{ booking.member_card[0]?.card_no }}
                      </button>
                      <button
                        style="
                          border-radius: 5px;
                          background-color: rgb(112 216 184);
                        "
                        v-if="booking.member_card.length == '2'"
                        @click="
                          cardDeatils(
                            booking.member_card[1]?.card_no,
                            booking.member_card[1]?.card_payment,
                            booking.is_active,
                            booking.id
                          )
                        "
                      >
                        {{ booking.member_card[1]?.card_no }}
                      </button>
                    </h6>
                  </td>
                  <td>{{ booking.password }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <dialog id="dialog" class="daialbox">
        <div class="table-responsive border-0">
          <div>
            <i
              class="fas fa-times-circle"
              style="
                font-size: 20px;
                color: rgb(42, 38, 38);
                float: right;
                cursor: pointer;
              "
              v-on:click="closeModal()"
            ></i>
            <h6>Card No : {{ paymentCardNumber }}</h6>

            <span style="color: red" v-if="!this.CheckPyNow"
              >Card has been deactivated due to delay in payment &nbsp;</span
            >
            <button
              style="border-radius: 10px; background-color: rgb(50 184 123)"
              v-if="!this.CheckPyNow"
              @click="activateMember(this.activateMemberId)"
            >
              Activate Now
            </button>
            <br />
          </div>

          <table class="table align-middle p-4 mb-0 table-hover table-shrink">
            <thead class="table-light">
              <tr>
                <th scope="col" class="border-0 rounded-start">#</th>
                <th scope="col" class="border-0">Amount</th>
                <th scope="col" class="border-0">Payment Due Date</th>
                <th scope="col" class="border-0">Payment Status</th>
                <th scope="col" class="border-0">Payment Image</th>
                <th scope="col" class="border-0">Action</th>
              </tr>
            </thead>
            <tbody class="border-top-0">
              <tr v-for="(booking, key) in paymentsDetails" :key="key">
                <td>
                  <h6 class="mb-0">{{ key + 1 }}</h6>
                </td>
                <td>
                  <span class="mb-0">{{ booking.amount }}</span>
                </td>
                <td>
                  <h6>
                    {{ moment(booking.payment_due_date).format("MMMM Do") }}
                  </h6>
                </td>
                <td v-if="booking.payment_status == true">
                  <img src="@/assets/banners/checked.png" />
                </td>
                <td v-if="booking.payment_status == false">
                  <img src="@/assets/banners/delete.png" />
                </td>
                <td v-if="booking.payment_image !== null">
                  <a :href="booking.payment_image" target="_blank">
                    <img
                      :src="booking.payment_image"
                      style="height: 100px; width: 100px"
                    />
                  </a>
                </td>
                <td v-if="booking.payment_image == null"></td>
                <td>
                  <button
                    style="
                      border-radius: 10px;
                      background-color: rgb(50 184 123);
                    "
                    v-if="booking.payment_datetime == null && this.CheckPyNow"
                    @click="finalPayment(booking.id)"
                  >
                    PAY NOW
                  </button>
                  <!--  -->
                  <h6 v-if="!this.CheckPyNow">-</h6>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </dialog>
    </section>
  </main>
</template>
<script>
var moment = require("moment");

export default {
  name: "Login",
  data() {
    return {
      moment: moment,
      card_image: "",
      alternate_mobile_number: "",
      address: "",
      cardMeth: "",
      itemMeth: "",
      postPayment: {
        payment_status: false,
        place: "",
        payment_mode: "",
      },
      paymentCardNumber: "",
      paymentsDetails: "",
      planData: {
        plan_name: "",
        duration: "",
        plan_amount: "",
        description: "",
        due_date: "",
      },
      plan_Add: false,
      mobileNumError: "",
      mobileMemberError: "",
      nameMemberError: "",
      planMemberError: "",
      errorqr_code: "",
      assignMemberError: "",
      cardLim: false,
      cardLimitExd: "",
      cardLimMem: false,
      cardLimitExdMem: "",
      cards: [],
      selectedCards: [],
      selectedCard: "",
      CardErrorMessage: "",
      cardErrorMsg: false,
      CardAdded: false,
      num_Added: "",
      new_card: {
        card_no: "",
      },
      error_same_password: "",
      add_User: false,
      dashUser: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        user_type: "",
      },
      add_card: true,
      fields: [{ value: "" }],
      add_agent: false,
      memOn: false,
      add_card: false,
      total_Cards: "",
      newAgent: {
        name: "",
        agent_mobile_number: "",
        card_ids: "",
      },
      total_memCards: "",
      mem_Plans: "",
      selMemberCard: "",
      selMemPlan: "",
      totalMemberList: "",
      payment_id: "",
      meta: {
        search: "",
      },
      //
      cardIdAssign: "",
      IsUserExist: "false",
      url: "",
      Payload: "",
      member_numberss: "",
      member_name: "",
      paymentError: "",
      PaymentModeCheck: false,
      CheckPyNow: true,
      activateMemberId: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getMemberCard();
    this.getMemberList();
    this.getMemPlans();
    this.cardIdAssign = this.$route.params.CardId;
    window.addEventListener("resize", this.checkScreenSize);
  },

  methods: {
    activateMember(id) {
      let vm = this;
      let loader = vm.$loading.show();
      vm.$store
        .dispatch("post", {
          uri: "administrator/member/status-update/",
          data: {
            member_id: id,
          },
        })
        .then((response) => {
          loader.hide();
          this.getMemberList();
          document.getElementById("dialog").close();
          console.log("activateNow", response);
        })
        .catch((error) => {
          loader.hide();
          console.log(error);
        });
    },
    closeModal() {
      document.getElementById("dialog").close();
    },
    getMemPlans() {
      let vm = this;
      vm.$store
        .dispatch("get", { uri: "administrator/plans/" })
        .then((response) => {
          this.mem_Plans = response.data;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    convertArrayToString(array) {
      return JSON.stringify(array);
    },
    cardDeatils(
      cardDeatils_Card_number,
      cardDeatils_items,
      is_Active,
      member_id
    ) {
      this.activateMemberId = member_id;
      this.cardMeth = cardDeatils_Card_number;
      this.itemMeth = cardDeatils_items;
      this.CheckPyNow = is_Active;
      this.paymentsDetails = cardDeatils_items;
      this.paymentCardNumber = cardDeatils_Card_number;
      console.log("Payment Status ", this.paymentsDetails);
      document.getElementById("dialog").showModal();
    },
    getMemberList() {
      let vm = this;
      console.log(this.meta.search.length);
      let url = "administrator/members";
      if (this.meta.search.length == 10) {
        url = "administrator/members/?mobile_number=%2B91" + this.meta.search;
      }
      vm.$store
        .dispatch("get", { uri: url })
        .then((response) => {
          this.totalMemberList = response?.data;
          console.log("aaaaaaaaaaaaaaaaaa", this.totalMemberList);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getMemberCard() {
      let vm = this;
      vm.$store
        .dispatch("get", { uri: "administrator/agent/members_list/" })
        .then((response) => {
          console.log("this.total_memCards", response);
          this.total_memCards = response?.data;
          this.IsUserExist = localStorage.getItem("ExistingUser");
          console.log("this.IsUserExist", this.IsUserExist);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    finalPayment(id) {
      this.PaymentModeCheck = true;
      this.payment_id = id;
      this.cardIdAssign = "0";
      console.log("this.payment_id", this.payment_id);
      document.getElementById("dialog").close();
      window.scrollTo(0, 0);
    },
    makePayment() {
      this.paymentError = "";
      if (this.postPayment.payment_mode == "" || this.postPayment.place == "") {
        this.paymentError = "Please Fill Above Details";
      } else {
        this.postPayment.payment_status = true;
        let loader = this.$loading.show();
        this.$store
          .dispatch("put", {
            uri: "payment/status/" + this.payment_id + "/update/",
            data: this.postPayment,
          })
          .then((response) => {
            loader.hide();
            this.getMemberList();
            this.postPayment.payment_mode = "";
            this.postPayment.place = "";
            this.PaymentModeCheck = false;
          })
          .catch((errors) => {
            loader.hide();
            console.log(errors);
          });
      }
      console.log("this.postPayment", this.postPayment);
    },
    getCardImage(event) {
      let CardImage = event.target.files[0];
      this.card_image = CardImage;
      console.log(this.card_image);
    },
    addMemDetails() {
      const formData = new FormData();
      this.mobileMemberError = "";
      this.nameMemberError = "";
      this.planMemberError = "";
      this.errorqr_code = "";
      if (this.IsUserExist == "false") {
        if (this.member_name == "") {
          this.nameMemberError = "Please Enter Name";
        } else if (this.member_numberss === "") {
          this.mobileMemberError = "The mobile number is Invalid";
        } else if (this.member_numberss.length != 10) {
          this.mobileMemberError = "The mobile number is Invalid";
        } else if (this.selMemPlan == "") {
          this.planMemberError = "Please Select Plan";
        } else if (this.card_image == "") {
          this.errorqr_code = "Please add card Image";
        } else if (
          this.mobileMemberError == "" &&
          this.nameMemberError == "" &&
          this.planMemberError == "" &&
          this.errorqr_code == ""
        ) {
          if (this.alternate_mobile_number !== "") {
            formData.append(
              "alternate_mobile_number",
              "+91" + this.alternate_mobile_number
            );
          }
          formData.append("name", this.member_name);
          formData.append("mobile_number", "+91" + this.member_numberss.trim());
          formData.append("card_id", this.cardIdAssign);
          formData.append("plan", this.selMemPlan);
          formData.append("card_image", this.card_image);

          formData.append("address", this.address);
          this.url = "administrator/member/assign-first-card/";
        }
      }
      if (this.IsUserExist == "true") {
        if (this.selMemberCard == "") {
          this.assignMemberError = "Please Select Member";
        } else if (this.card_image == "") {
          this.errorqr_code = "Please add card Image";
        } else {
          formData.append("card_id", this.cardIdAssign);
          formData.append("user_id", this.selMemberCard);
          formData.append("second_card_image", this.card_image);
          this.url = "administrator/member/assign-second-card/";
        }
      }
      if (this.url !== "") {
        let loader = this.$loading.show();
        this.$store
          .dispatch("postWinner", {
            uri: this.url,
            data: formData,
          })
          .then((response) => {
            loader.hide();
            this.getMemberList();
            this.cardIdAssign = "0";
            this.this.member_numberss = "";
            this.this.member_name = "";
            this.this.selMemPlan = "";
          })
          .catch((errors) => {
            loader.hide();
            this.errorqr_code = "A user can have maximum of 2 cards";
          });
      }
    },
  },
};
</script>
<style>
.daialbox {
  border: 1px solid #ffffff00;
  border-radius: 8px;
  padding: 2%;
  overflow-y: auto;
}
</style>
