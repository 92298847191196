<template>
    <main>
        <div class="container" style="text-align: left;">
          <br>
          <br>
          <img style="width: 80%;" class="mb-4" src="https://www.devalayas.in/assets/images/pp.png" alt="" />
          
          <br>
          <p><h5>Privacy Policy :</h5></p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;At Samruddhi Members Mobile App, we are committed to protecting the privacy and confidentiality of our users' personal information. By using our mobile app and participating in the scheme described above, users agree to the following privacy policy:&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;Collection of Information: We collect personal information from users for the purpose of scheme participation, including but not limited to name, contact details, payment information, and preferences.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;Use of Information: The information collected is used solely for the administration of the scheme, including processing payments, conducting draws, delivering prizes, and communicating with users regarding scheme-related matters. We do not share or sell user information to third parties for marketing purposes.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;Security Measures: We employ industry-standard security measures to safeguard user information against unauthorized access, disclosure, alteration, or destruction. This includes encryption of data transmission, secure storage practices, and access controls.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;Retention of Information: User information is retained for the duration necessary to fulfill the purposes outlined in the scheme. After the scheme concludes, user information may be retained for legal or regulatory requirements.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;Rights of Users: Users have the right to access, update, or delete their personal information. They can also request information about the processing of their data and object to certain processing activities.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;Consent: By using the Samruddhi Members Mobile App and participating in the scheme, users consent to the collection, processing, and use of their personal information as outlined in this privacy policy.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;Changes to Privacy Policy: We reserve the right to update or modify this privacy policy at any time. Users will be notified of any significant changes, and continued use of the app constitutes acceptance of the revised policy.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;Contact Information: For questions or concerns regarding this privacy policy or the handling of personal information, users can contact us at [insert contact details].&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;By using the Samruddhi Members Mobile App and participating in the scheme, users acknowledge and agree to the terms of this privacy policy.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
      </div>
    </main>
</template>

<script>

export default {  
    name: "Privacy",
    mounted(){
      window.scrollTo(0,0);
       },
      };

</script>