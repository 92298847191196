<template>
  <div class="container">
    <br />
    <img style="height: 80%" src="@/assets/banners/pampImage/1.jpg" />
    <br />
    <img style="height: 80%" src="@/assets/banners/pampImage/2.jpg" />
    <br />
    <img style="height: 80%" src="@/assets/banners/pampImage/3.jpg" />
    <br />
    <img style="height: 80%" src="@/assets/banners/pampImage/4.jpg" />
    <br />
  </div>
</template>
