<template>
  <header class="navbar-light header-sticky">
    <nav class="navbar navbar-light navbar-expand-xl">
      <div class="container">
        <button
          class="navbar-toggler ms-auto me-3 p-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-animation">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>
        <router-link to="/" class="navbar-brand">
          <img
            class="light-mode-item navbar-brand-item"
            src="@/assets/logo.png"
            alt="logo"
          />
        </router-link>

        <div class="navbar-collapse collapse" id="navbarCollapse">
          <ul class="navbar-nav navbar-nav-scroll mx-auto">
            <li class="nav-item">
              <router-link class="nav-link custom-menu" to="/"
                >Home</router-link
              >
            </li>
            <li
              v-if="this.$store.getters.user == 'Super_Admin'"
              class="nav-item"
            >
              <router-link class="nav-link custom-menu" to="/User"
                >Add User</router-link
              >
            </li>
            <li v-if="this.$store.getters.user == 'Admin'" class="nav-item">
              <router-link class="nav-link custom-menu" to="/Agent"
                >Add Associate</router-link
              >
            </li>
            <li
              v-if="this.$store.getters.user == 'Super_Admin'"
              class="nav-item"
            >
              <router-link class="nav-link custom-menu" to="/Card"
                >Add Cards</router-link
              >
            </li>
            <li v-if="this.$store.getters.user == 'Associate'" class="nav-item">
              <router-link class="nav-link custom-menu" to="/Member/0"
                >Members Payment</router-link
              >
            </li>
            <li v-if="this.$store.getters.user == 'Associate'" class="nav-item">
              <router-link class="nav-link custom-menu" to="/privacy_policy"
                >Privacy Policy</router-link
              >
            </li>
            <li v-if="this.$store.getters.user == 'Associate'" class="nav-item">
              <router-link class="nav-link custom-menu" to="/terms_conditions"
                >Terms Conditions</router-link
              >
            </li>
            <li
              v-if="this.$store.getters.user == 'Super_Admin'"
              class="nav-item"
            >
              <router-link class="nav-link custom-menu" to="/Plan"
                >Add Plan</router-link
              >
            </li>
            <li
              v-if="this.$store.getters.user == 'Super_Admin'"
              class="nav-item"
            >
              <router-link class="nav-link custom-menu" to="/Winner"
                >Winners</router-link
              >
            </li>
            <li
              v-if="this.$store.getters.user == 'Super_Admin'"
              class="nav-item"
            >
              <router-link class="nav-link custom-menu" to="/distributer"
                >Add Distributer</router-link
              >
            </li>
          </ul>
        </div>
        <ul class="nav flex-row align-items-center list-unstyled ms-xl-auto">
          <li
            v-if="this.$store.getters.user == 'Super_Admin'"
            style="color: black"
            class="nav-link custom-menu"
          >
            <div>Super Admin : {{ name }}</div>
          </li>
          <li
            v-if="this.$store.getters.user == 'Associate'"
            style="color: black"
            class="nav-link custom-menu"
          >
            <div>Associate : {{ name }}</div>
          </li>
          <li
            class="nav-link custom-menu"
            v-if="this.$store.getters.user == 'Admin'"
            style="color: black"
          >
            <div>Distributer : {{ name }}</div>
          </li>
          <li class="nav-link custom-menu">
            <button
              class="btn btn-primary"
              style="border-radius: 70px"
              @click.prevent="logout"
            >
              <i class="bi bi-power fa-fw me-2"></i>
              Sign Out
            </button>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      storedMobilvczvzxveNumber: "",
      name: "",
    };
  },
  mounted() {
    this.name = localStorage.getItem("User_Name");
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      // window.open("https://stagingvle.cscestore.in/auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style>
.custom-menu {
  font-size: auto;
  color: rgb(255, 255, 255);
  font-family: commanders;
}
</style>
