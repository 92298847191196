// firebase.js
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC_yGrfPGLR00QQKIu0A21vccq551AZCis",
  authDomain: "samruddhi-72b72.firebaseapp.com",
  projectId: "samruddhi-72b72",
  storageBucket: "samruddhi-72b72.appspot.com",
  messagingSenderId: "921376744531",
  appId: "1:921376744531:web:e6c03073fec4facceb12a6",
  measurementId: "G-G831LBH1RV",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
