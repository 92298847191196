<template>
  <main>
    <section>
      <div class="container">
        <div
          v-if="num_Added != ''"
          class="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          <strong>Successfully Created Associate {{ num_Added }}.</strong>
        </div>
        <div class="card-body">
          <div class="mb-2">
            <label class="form-label">
              Associate Name <span class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Associate Name"
              v-model="agent_name"
            />
            <span v-if="NameError != ''" style="color: red">{{
              NameError
            }}</span>
          </div>
          <div class="mb-2">
            <label class="form-label"
              >Associate Mobile Number <span class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Associate Number"
              maxlength="10"
              v-model="agent_number"
            />
            <span v-if="mobileNumError != ''" style="color: red">{{
              mobileNumError
            }}</span>
          </div>

          <div class="container">
            <div class="table-responsive border-0">
              <button class="btn btn-primary" @click="selectNext11">
                Select 11 Cards
              </button>
              &nbsp;
              <button class="btn btn-primary" @click="deselectAll">
                Deselect All
              </button>
              <table
                class="table align-middle p-4 mb-0 table-hover table-shrink"
              >
                <thead class="table-light">
                  <tr>
                    <th scope="col" class="border-0 rounded-start">#</th>
                    <th scope="col" class="border-0">Card Number</th>
                    <th scope="col" class="border-0">Add Cards</th>
                    <th scope="col" class="border-0">Surrender Cards</th>
                  </tr>
                </thead>
                <tbody class="border-top-0">
                  <tr v-for="(item, index) in totalEmptyCards" :key="key">
                    <td>
                      <h6 class="mb-0">{{ index + 1 }}</h6>
                    </td>
                    <td>
                      {{ item.card_no }}
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        :id="'checkbox-' + index"
                        :value="item.id"
                        v-model="selectedItems"
                        @change="saveSelection"
                      />
                      <label :for="'checkbox-' + index">SELECT</label>
                    </td>
                    <td>
                      <button
                        class="btn btn-primary"
                        @click="dleletCrads(item.id)"
                      >
                        Delete Card
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <span v-if="errorLogin != ''" style="color: red">{{
            errorLogin
          }}</span>
        </div>
        <button class="btn btn-primary" @click.prevent="addagentDetails">
          Submit
        </button>
      </div>
    </section>
  </main>
</template>
<script>
import axios from "axios";
export default {
  name: "Login",
  data() {
    return {
      cardMeth: "",
      itemMeth: "",
      postPayment: {
        payment_status: false,
      },
      paymentCardNumber: "",
      paymentsDetails: "",
      planData: {
        plan_name: "",
        duration: "",
        plan_amount: "",
        description: "",
        due_date: "",
      },
      plan_Add: false,
      mobileNumError: "",
      NameError: "",
      mobileMemberError: "",
      cardLim: false,
      cardLimitExd: "",
      cardLimMem: false,
      cardLimitExdMem: "",
      cards: [],
      selectedCard: null,
      selectedCards: [],
      FinalCards: [],
      CardErrorMessage: "",
      cardErrorMsg: false,
      CardAdded: false,
      num_Added: "",
      new_card: {
        card_no: "",
      },
      error_same_password: "",
      add_User: false,
      dashUser: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        user_type: "",
      },
      add_card: true,
      fields: [{ value: "" }], // Initial input field
      add_agent: false,
      memOn: false,
      add_card: false,
      totalEmptyCards: "",
      newAgent: {
        name: "",
        mobile_number: "",
        card_ids: "",
      },
      newMember: {
        name: "",
        mobile_number: "",
        card_ids: "",
      },
      total_memCards: "",
      selMemberCard: "",
      selMemberCards: [],
      totalMemberList: "",
      errorLogin: "",
      cardIdToRemove: "",
      agent_number: "",
      agent_name: "",
      selectedSublist: null,
      packedList: [],
      extractedData: [],
      flattenedData: [],
      selectedCard: null,
      selectedItems: [],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getCard();
  },
  methods: {
    dleletCrads(id) {
      let vm = this;
      let loader = vm.$loading.show();
      vm.$store
        .dispatch("post", {
          uri: "administrator/update-card/" + id + "/",
          data: { field: "distributer" },
        })
        .then((response) => {
          loader.hide();
          this.getCard();
          console.log("deleted", response);
        })
        .catch((error) => {
          loader.hide();
          console.log(error);
        });
    },
    saveSelection() {
      localStorage.setItem("selectedItems", JSON.stringify(this.selectedItems));
      console.log(JSON.stringify(this.selectedItems));
    },
    selectNext11() {
      let startIndex = this.lastSelectedIndex + 1 || 0;
      let endIndex = Math.min(startIndex + 11, this.totalEmptyCards.length);
      for (let i = startIndex; i < endIndex; i++) {
        this.selectedItems.push(this.totalEmptyCards[i].id);
      }
      this.lastSelectedIndex = endIndex - 1;
      this.saveSelection();
    },
    deselectAll() {
      this.selectedItems = [];
    },
    selectSublist(index) {
      this.selectedSublist = this.packedList[index - 1];
    },
    removeCard(cardId) {
      console.log("Removing card with ID:", cardId);
      this.cardIdToRemove = cardId;
      this.selectedCards = this.selectedCards.filter(
        (card) => card.id !== this.cardIdToRemove
      );
      console.log("Selected card ID:", JSON.stringify(this.selectedCards));
    },
    convertArrayToString(array) {
      return JSON.stringify(array);
    },
    getCard() {
      let vm = this;
      vm.$store
        .dispatch("get", { uri: "administrator/agent/empty-cards/" })
        .then((response) => {
          console.log(response);
          this.totalEmptyCards = response.data;
          const apiData = response.data;
          const sublists = [];
          let sublist = [];

          for (let i = 0; i < apiData.length; i++) {
            sublist.push(apiData[i].id);
            if ((i + 1) % 100 === 0 || i === apiData.length - 1) {
              sublists.push(sublist);
              sublist = [];
            }
          }

          console.log("subltotalEmptyCardsists", this.totalEmptyCards);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    addagentDetails() {
      this.errorLogin = "";
      this.mobileNumError = "";
      // this.FinalCards = this.selectedCards.map((card) => card.id);
      this.FinalCards = JSON.stringify(this.selectedItems);
      this.NameError = "";
      this.num_Added = "";
      if (this.agent_name === "") {
        this.NameError = "Please Enter Name";
      } else if (this.agent_number.length !== 10) {
        this.mobileNumError = "The mobile number is Invalid";
      } else if (this.selectedItems.length === 0) {
        this.errorLogin = "Please assign Cards";
      } else {
        this.newAgent.name = this.agent_name;
        this.newAgent.mobile_number = "+91" + this.agent_number;
        this.newAgent.card_ids = JSON.stringify(this.selectedItems);
        console.log(this.newAgent);
        let loader = this.$loading.show();
        this.$store
          .dispatch("post", {
            uri: "administrator/agent/create/",
            data: this.newAgent,
          })
          .then((response) => {
            loader.hide();
            this.getCard();
            this.num_Added = this.agent_name;
            this.agent_name = "";
            this.agent_number = "";
            this.selectedItems = [];
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            loader.hide();
            this.errorLogin = error.response.data.error;
            // console.log(error.response.data.error);
          });
      }
    },
  },
};
</script>
