<template>
  <main>
    <section>
      <div class="container">
        <div class="card-body">
          <div
            v-if="userAdded"
            class="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <strong>Successfully Created User {{ num_Added }}.</strong>
          </div>
          <div class="mb-2">
            <label class="form-label">
              Name <span class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Name"
              v-model="dashUser.name"
            />
          </div>
          <div class="mb-2">
            <label class="form-label">
              Email Id <span class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Email Id"
              v-model="dashUser.email"
            />
          </div>
          <div class="mb-2">
            <label class="form-label"
              >Password <span class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Password"
              v-model="dashUser.password"
            />
          </div>
          <div class="mb-2">
            <label class="form-label"
              >Confirm Password <span class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Confirm Password"
              v-model="dashUser.confirm_password"
            />
          </div>
          <div class="mb-2">
            <label class="form-label"
              >User Type <span class="text-red">*</span>
            </label>
            <select class="form-control" v-model="dashUser.user_type">
              <option dashUser.user_type="Super_Admin">Super Admin</option>
            </select>
          </div>
          <div v-if="show">
            <h6>{{ error_same_password }}</h6>
          </div>
          <!--  -->
          <div class="col-lg-2 d-grid">
            <a
              class="btn btn-lg btn-primary mb-0"
              href="#"
              @click.prevent="submitDshUser"
              >Submit User
            </a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import axios from "axios";
export default {
  name: "Login",
  data() {
    return {
      show: false,
      userAdded: false,
      error_same_password: "",
      dashUser: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        user_type: "",
      },
    };
  },

  methods: {
    submitDshUser() {
      let vm = this;
      if (this.dashUser.name == "") {
        this.error_same_password = "Please Enter Name";
        this.show = true;
      } else if (this.dashUser.email == "") {
        this.error_same_password = "Please Enter Email";
        this.show = true;
      } else if (this.dashUser.password == "") {
        this.error_same_password = "Please Enter Password";
        this.show = true;
      } else if (this.dashUser.confirm_password == "") {
        this.error_same_password = "Please confirm Password";
        this.show = true;
      } else if (this.dashUser.confirm_password != this.dashUser.password) {
        this.error_same_password = "Incorrect Password";
        this.show = true;
      }
      if (this.error_same_password == "") {
        let loader = this.$loading.show();
        this.$store
          .dispatch("post", {
            uri: "administrator/create-user/",
            data: vm.dashUser,
          })
          .then((response) => {
            loader.hide();
            this.userAdded = true;
          })
          .catch((errors) => {
            loader.hide();
          });
      }
    },
  },
};
</script>
