<template>
  <main>
    <!-- Search -->
    <div class="container" style="padding: 30px">
      <div class="row g-3 justify-content-center align-items-center">
        <div class="col-lg-10">
          <div class="form-input-dropdown position-relative">
            <input
              v-model="searchText"
              placeholder="Enter Card Number"
              class="form-control form-control-lg me-1 ps-5"
              type="text"
            />
          </div>
        </div>
        <div class="col-lg-2 d-grid">
          <a
            class="btn btn-lg btn-primary mb-0"
            href="#"
            @click.prevent="searchCard"
            >Search</a
          >
        </div>
      </div>
    </div>
    <!-- Search -->
    <div class="container">
      <section>
        <div class="table-responsive border-0">
          <table class="table align-middle p-4 mb-0 table-hover table-shrink">
            <thead class="table-light">
              <tr v-if="!searchmsg && !noCards">
                <th scope="col" class="border-0 rounded-start">#</th>
                <th scope="col" class="border-0">Card Number</th>
                <th scope="col" class="border-0">Action</th>
              </tr>
            </thead>
            <div v-if="searchmsg" class="container">
              <h6 style="text-align: center">No Search Found</h6>
              <br />
            </div>
            <div v-if="noCards" class="container">
              <h6 style="text-align: center">No Cards Found</h6>
              <br />
            </div>
            <button class="btn btn-primary" @click="selectNext100">
              Select Next 110
            </button>
            <button class="btn btn-primary" @click="selectNext50">
              Select Next 55
            </button>
            <button class="btn btn-primary" @click="deselectAll">
              Deselect All
            </button>
            <tbody class="border-top-0">
              <tr v-for="(item, index) in totalEmptyCards" :key="key">
                <td>
                  <h6 class="mb-0">{{ index + 1 }}</h6>
                </td>
                <td>
                  {{ item.card_no }}
                </td>
                <td>
                  <input
                    type="checkbox"
                    :id="'checkbox-' + index"
                    :value="item.id"
                    v-model="selectedItems"
                    @change="saveSelection"
                  />
                  <label :for="'checkbox-' + index">SELECT</label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <div v-if="!searchmsg && !noCards" class="card-footer pt-0">
        <button class="btn btn-lg btn-primary mb-0" @click="submitDistrub">
          Submit
        </button>
      </div>
      <div class="card-footer pt-0">
        <Pagination
          class="pagination pagination-sm"
          :maxPage="maxPage"
          :totalPages="lastPage"
          :currentPage="page"
          @pagechanged="onPageChange"
        />
      </div>
    </div>
  </main>
</template>
<script>
import Pagination from "../components/Pagination.vue";

export default {
  name: "AssignCards",
  components: { Pagination },
  mounted() {
    this.distrub.distributer = this.$route.params.Dist;
    this.assignCards();
    window.scrollTo(0, 0);
    const savedSelection = localStorage.getItem("selectedItems");
    if (savedSelection) {
      this.selectedItems = JSON.parse(savedSelection);
    }
  },

  data() {
    return {
      distrub: {
        distributer: "",
        id: "",
      },
      selectedItems: [],
      item: [],
      totalEmptyCards: "",
      searchText: "",
      searchmsg: false,
      noCards: false,
      page: 1,
      size: 10,
      count: 0,
      maxPage: 1,
      lastPage: 1,
      search: "",
      ordering: "",
      buttontrue: false,
      totalEmptyCards: [],
    };
  },

  methods: {
    submitDistrub() {
      let vm = this;
      let loader = this.$loading.show();
      vm.distrub.id = JSON.stringify(this.selectedItems);
      this.$store
        .dispatch("put", {
          uri: "administrator/distributer/assign-cards",
          data: this.distrub,
        })
        .then((response) => {
          localStorage.removeItem("selectedItems");
          this.$router.push({
            name: "home",
          });
          loader.hide();
        })
        .catch((errors) => {
          loader.hide();
          console.log(errors);
        });
    },
    saveSelection() {
      localStorage.setItem("selectedItems", JSON.stringify(this.selectedItems));
      console.log(JSON.stringify(this.selectedItems));
    },
    selectNext100() {
      let startIndex = this.lastSelectedIndex + 1 || 0;
      let endIndex = Math.min(startIndex + 110, this.totalEmptyCards.length);
      for (let i = startIndex; i < endIndex; i++) {
        this.selectedItems.push(this.totalEmptyCards[i].id);
      }
      this.lastSelectedIndex = endIndex - 1;
      this.saveSelection();
    },
    selectNext50() {
      let startIndex = this.lastSelectedIndex + 1 || 0;
      let endIndex = Math.min(startIndex + 55, this.totalEmptyCards.length);
      for (let i = startIndex; i < endIndex; i++) {
        this.selectedItems.push(this.totalEmptyCards[i].id);
      }
      this.lastSelectedIndex = endIndex - 1;
      this.saveSelection();
    },
    deselectAll() {
      this.selectedItems = [];
      localStorage.removeItem("selectedItems");
    },
    addedandremove(id) {
      const index = this.selectedItems.indexOf(id);
      if (index === -1) {
        this.selectedItems.push(id);
      } else {
        this.selectedItems.splice(index, 1);
      }
      this.saveSelection();
    },
    onPageChange(page) {
      this.page = page;
      this.assignCards();
    },
    searchCard() {
      let vm = this;
      this.page = 1;
      this.searchmsg = false;
      this.searchText = this.searchText.trim();
      vm.$store
        .dispatch("get", {
          uri:
            "administrator/distributer/empty-cards/?page=" +
            this.page +
            "&size=" +
            this.size +
            "&card_no=" +
            this.searchText,
        })
        .then((response) => {
          this.totalEmptyCards = response?.data.results;
          vm.count = response.data.count;
          vm.lastPage = Math.ceil(vm.count / vm.size);
          vm.maxPage = vm.lastPage >= 3 ? 3 : vm.lastPage;
          console.log(response?.data);
          if (response.data.count == 0) {
            this.searchmsg = true;
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    assignCards() {
      let vm = this;
      vm.$store
        .dispatch("get", {
          uri: "administrator/distributer/empty-cards/",
        })
        .then((response) => {
          this.totalEmptyCards = response?.data;
          console.log("response11111111111111", response);
          vm.count = response.data.count;
          if (vm.count == 0) {
            vm.noCards = true;
          }
          vm.lastPage = Math.ceil(vm.count / vm.size);
          vm.maxPage = vm.lastPage >= 3 ? 3 : vm.lastPage;
          console.log(vm.count, vm.lastPage, vm.maxPage);
          console.log("assignCards", this.totalEmptyCards);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>
