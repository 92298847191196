<template>
  <main>
    <section>
      <div class="container">
        <div class="card-body">
          <div class="mb-2">
            <div
              v-if="CardAdded"
              class="alert alert-success alert-dismissible fade show"
              role="alert"
            >
              <strong>Successfully Added Card {{ num_Added }}.</strong>
            </div>

            <label class="form-label">
              Card Number<span class="text-red">*</span>
            </label>
            <div v-if="cardErrorMsg">
              <p style="color: red">{{ CardErrorMessage }}.</p>
            </div>
            <div>
              <input
                type="text"
                class="form-control"
                style="width: 30%; display: inline"
                placeholder="Prefix"
                v-model="new_card.prefix"
              />
              -
              <input
                type="text"
                class="form-control"
                style="width: 30%; display: inline"
                placeholder="Start Number"
                v-model="new_card.from"
              />
              TO
              <input
                type="text"
                class="form-control"
                style="width: 30%; display: inline"
                placeholder="End Number"
                v-model="new_card.to"
              />
            </div>
            <span v-if="cardErrorMessage != ''" style="color: red">{{
              cardErrorMessage
            }}</span>
            <br />
            <button class="btn btn-primary" @click="addField">Submit</button>
          </div>
        </div>
        <section>
          <div class="table-responsive border-0">
            <table class="table align-middle p-4 mb-0 table-hover table-shrink">
              <thead class="table-light">
                <tr>
                  <th scope="col" class="border-0 rounded-start">#</th>
                  <th scope="col" class="border-0">Card Number</th>
                </tr>
              </thead>
              <tbody class="border-top-0">
                <tr v-for="(item, index) in TotalCards" :key="key">
                  <td>
                    <h6 class="mb-0">{{ index + 1 }}</h6>
                  </td>
                  <td>
                    {{ item.card_no }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "Login",
  data() {
    return {
      cardMeth: "",
      itemMeth: "",
      postPayment: {
        payment_status: false,
      },
      paymentCardNumber: "",
      paymentsDetails: "",
      planData: {
        plan_name: "",
        duration: "",
        plan_amount: "",
        description: "",
        due_date: "",
      },
      plan_Add: false,
      mobileNumError: "",
      mobileMemberError: "",
      cardLim: false,
      cardLimitExd: "",
      cardLimMem: false,
      cardLimitExdMem: "",
      cards: [],
      selectedCards: [],
      selectedCard: "",
      CardErrorMessage: "",
      cardErrorMsg: false,
      CardAdded: false,
      num_Added: "",
      new_card: {
        prefix: "",
        from: "",
        to: "",
      },
      error_same_password: "",
      add_User: false,
      dashUser: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        user_type: "",
      },
      add_card: true,
      fields: [{ value: "" }], // Initial input field
      add_agent: false,
      memOn: false,
      add_card: false,
      total_Cards: "",
      newAgent: {
        name: "",
        agent_mobile_number: "",
        card_ids: "",
      },
      newMember: {
        name: "",
        mobile_number: "",
        card_ids: "",
      },
      total_memCards: "",
      selMemberCard: "",
      selMemberCards: [],
      totalMemberList: "",
      cardErrorMessage: "",
      TotalCards: "",
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getCardList();
  },

  methods: {
    getCardList() {
      let vm = this;
      vm.$store
        .dispatch("get", { uri: "administrator/cards/" })
        .then((response) => {
          this.TotalCards = response?.data;
          console.log("TotalCards", response);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    addField() {
      this.cardErrorMessage = "";
      if (this.new_card.prefix == "") {
        this.cardErrorMessage = "Please Enter Prefix";
      } else if (this.new_card.from == "") {
        this.cardErrorMessage = "Enter Start Number";
      } else if (this.new_card.to == "") {
        this.cardErrorMessage = "Enter End Number";
      } else if (this.new_card.from >= this.new_card.to) {
        this.cardErrorMessage = "Enter Card In Valid Format : (1 to 1000)";
      } else {
        this.new_card.prefix = this.new_card.prefix + "-";
        let loader = this.$loading.show();
        this.$store
          .dispatch("post", {
            uri: "administrator/add-cards/",
            data: this.new_card,
          })
          .then((response) => {
            loader.hide();
            this.getCardList();
            this.cardErrorMsg = false;
            this.CardAdded = true;
            this.num_Added = this.new_card.card_no;
            this.new_card.card_no = "";
          })
          .catch((error) => {
            loader.hide();
            console.log(error);
            if (error.message == "Request failed with status code 400") {
              this.cardErrorMsg = true;
              this.CardErrorMessage = "Card with this card no already exists.";
            }
          });
      }
    },
    addagentDetails() {
      this.mobileNumError = "";
      if (this.agent_number.length != 10) {
        this.mobileNumError = "The mobile number is Invalid";
      }
      if (this.mobileNumError == "") {
        this.newAgent.name = this.agent_name;
        this.newAgent.agent_mobile_number = "+91" + this.agent_number;
        this.newAgent.card_ids = this.convertArrayToString(this.selectedCards);

        let loader = this.$loading.show();
        this.$store
          .dispatch("post", {
            uri: "administrator/agent/create/",
            data: this.newAgent,
          })
          .then((response) => {
            loader.hide();
          })
          .catch((errors) => {
            loader.hide();
            console.log(errors);
          });
      }
    },
  },
};
</script>
