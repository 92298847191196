import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store/index";

import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import Agent from "@/views/Agent.vue";
import Card from "@/views/Card.vue";
import Member from "@/views/Member.vue";
import Plan from "@/views/Plan.vue";
import User from "@/views/User.vue";
import Winner from "@/views/Winner.vue";
import Distributer from "@/views/Distributer.vue";
import AssignCards from "@/views/AssignCards.vue";
import Terms from "../views/Terms.vue";
import Privacy from "../views/Privacy.vue";
import Pdf from "../views/Pdf.vue";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/terms_conditions",
      name: "Terms",
      component: Terms,
    },
    {
      path: "/scheme_pdf",
      name: "Pdf",
      component: Pdf,
    },
    {
      path: "/privacy_policy",
      name: "Privacy",
      component: Privacy,
    },
    {
      path: "/Winner",
      name: "Winner",
      component: Winner,
      meta: { auth: true },
    },
    {
      path: "/AssignCards/:Dist",
      name: "AssignCards",
      component: AssignCards,
      meta: { auth: true },
    },
    {
      path: "/distributer",
      name: "Distributer",
      component: Distributer,
      meta: { auth: true },
    },
    {
      path: "/User",
      name: "User",
      component: User,
      meta: { auth: true },
    },
    {
      path: "/Plan",
      name: "Plan",
      component: Plan,
      meta: { auth: true },
    },
    {
      path: "/Member/:CardId",
      name: "Member",
      component: Member,
      meta: { auth: true },
    },
    {
      path: "/Card",
      name: "Card",
      component: Card,
      meta: { auth: true },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: { unAuth: true },
    },
    {
      path: "/Agent",
      name: "Agent",
      component: Agent,
      meta: { auth: true },
    },
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { auth: true },
    },
  ],
});

router.beforeEach(function (to, _, next) {
  if (to.meta.auth && !store.getters.user) {
    next("login");
  } else if (to.meta.unAuth && store.getters.user) {
    next("/");
  } else {
    next();
  }
});

export default router;
